import { dynamodb } from './dynamoClient.js';

/**
 * This function is used for adding different attributes into the DynamoDB, and it can be 
 * used for future edit on new attributes.
 */
function saveToDynamoDB(tableName, item) {
  const params = {
    TableName: tableName,
    Item: {
      'id': { 'S': item.meetingId }, // This field is primary key. Since meetingId is unique, it can used as a primary key.
      'text': { 'S': JSON.stringify(item.text) }, // In order to save reply from gpt, the text need to be a list.
      'user_id': { 'S': item.userId || '' },
      'created_at': { 'N': item.timestamp },
      'meeting_id': { 'S': item.meetingId }, // The interview database will share same meetingID that save in session.
      'isHidden': {'BOOL': item.isHidden || false }
    }
  };

  dynamodb.putItem(params, function(err, data) {
    if (err) console.log(err);
  });
}

/**
 * This method add chat history to the DynamoDB, it first checks if the meetingID exists,
 * removes the chat history then adds it to the DynamoDB.
 * @param {*} item 
 */
export function addInterviewToDynamoDB(item) {
  const tableName = 'interview_history';
  // Try retrieveInterviewTextFromDynamoDB, update if current meetingID is found in DynamoDB.
  retrieveInterviewTextFromDynamoDB(item.meetingId).then( res => {
    if (res.Count > 0) { // The same meeting is found in DynamoDB.
      const key = {
        TableName: 'interview_history',
        Key: {
          'id': { 'S': item.meetingId } // The update operation only support primary key.
        },          
        UpdateExpression: 'SET #text =:newText',
        ExpressionAttributeNames: {
            '#text': 'text' // COLUMN NAME       
        },
        ExpressionAttributeValues: {
            ':newText': { 'S': JSON.stringify(item.text) },
        }
      };
      dynamodb.updateItem(key, function(err, data) {
        if (err) { console.log('Error :' + err); }
      });
    } else {
      saveToDynamoDB(tableName, item);
    }
  });
}

/**
 * This function helps hide the interview histories from user in the interview page.
 * @param {*} item
 * @returns 
 */
export function hideHistoryFromUser(item){
  // Try retrieveInterviewTextFromDynamoDB, update if current meetingID is found in DynamoDB.
  retrieveInterviewTextFromDynamoDB(item.meetingId).then( res => {
    if (res.Count > 0) { // The same meeting is found in DynamoDB.
      const key = {
        TableName: 'interview_history',
        Key: {
          'id': { 'S': item.meetingId } // The update operation only support primary key.
        },          
        UpdateExpression: 'SET #hiddenAttr =:hiddenValue',
        ExpressionAttributeNames: {
            '#hiddenAttr': 'isHidden' // COLUMN NAME       
        },
        ExpressionAttributeValues: {
            ':hiddenValue': { 'BOOL': true },
        }
      };
      dynamodb.updateItem(key, function(err, data) {
        if (err) { console.log('Error :' + err); }
      });
    } else {
      saveToDynamoDB('interview_history', item);
    }
  });
}

/**
 * This function helps hide the interview histories from user in the meeting page.
 * @param {*} item
 * @returns 
 */
export function hideHistoryFromMeetingUser(item){
  // Try retrieveInterviewTextFromDynamoDB, update if current meetingID is found in DynamoDB.
  retrieveMeetingFromDynamoDB(item.meetingId).then( res => {
    if (res.Count > 0) { // The same meeting is found in DynamoDB.
      const key = {
        TableName: 'chat_history',
        Key: {
          'id': { 'S': item.meetingId } // The update operation only support primary key.
        },          
        UpdateExpression: 'SET #hiddenAttr =:hiddenValue',
        ExpressionAttributeNames: {
            '#hiddenAttr': 'isHidden' // COLUMN NAME       
        },
        ExpressionAttributeValues: {
            ':hiddenValue': { 'BOOL': true },
        }
      };
      dynamodb.updateItem(key, function(err, data) {
        if (err) { console.log('Error :' + err); }
      });
    } else {
      saveToDynamoDB('chat_history', item);
    }
  });
}

export function retrieveInterviewTextFromDynamoDB(meetingId) {
  const params = {
    TableName: 'interview_history',
    ExpressionAttributeValues: {
      ':meeting_id': { S: meetingId }
    },
    FilterExpression: 'meeting_id = :meeting_id'
  };

  return new Promise((resolve, reject) => {
    dynamodb.scan(params, function(err, data) {
      if (err) {
        reject(err);
      } else {
        resolve(data);
      }
    });
  });
}

export function retrieveMeetingFromDynamoDB(meetingId) {
  const params = {
    TableName: 'chat_history',
    ExpressionAttributeValues: {
      ':meeting_id': { S: meetingId }
    },
    FilterExpression: 'meeting_id = :meeting_id'
  };

  return new Promise((resolve, reject) => {
    dynamodb.scan(params, function(err, data) {
      if (err) {
        reject(err);
      } else {
        resolve(data);
      }
    });
  });
}

/**
 * This function retrieves all the interview histories from the user.
 * @param {*} userId 
 * @returns complete history details of an user.
 */

export function retrieveInterviewFromDynamoDB(userId) {
  const params = {
    TableName: 'interview_history',
    ExpressionAttributeValues: {
      ':user_id': { S: userId }
    },
    FilterExpression: 'user_id = :user_id'
  };

  return new Promise((resolve, reject) => {
    dynamodb.scan(params, function(err, data) {
      if (err) {
        reject(err);
      } else {
        resolve(data);
      }
    });
  });
}