import React, { useState, useContext, cloneElement, useEffect, version, useRef } from 'react';
import ChatButton from './ChatButton';
import SpeechClient from './clients/speechClient';
import { addItemToDynamoDB, retrieveFromDynamoDB } from './clients/dynamoClient';
import dataContext from './dataContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const ContextProvider = ({ children }) => {
  const [isStored, setIsStored] = useState(false);
  const [responses, setResponses] = useState([]);
  const [history, setHistory] = useState(''); // this state is used in ChatButton.js to display retrieved meeting note.
  const [meetingPageGptVersion, setMeetingPageGptVersion] = useState("gpt-3.5-turbo");
  const userKey = useRef(null); // User own OpenAI key

  return (
    <dataContext.Provider value={{isStored, setIsStored, responses, setResponses, history, setHistory, meetingPageGptVersion, setMeetingPageGptVersion, userKey}}>
      {children}
    </dataContext.Provider>
  )
};

function SpeechToText() {
  const [isDisabled, setIsDisabled] = useState(false);
  const [text, setText] = useState('');
  const { isStored, setIsStored, meetingPageGptVersion, setMeetingPageGptVersion, userKey } = useContext(dataContext);
  const [recognizer, setRecognizer] = useState(null);
  const [chatInput, setChatInput] = useState('');
  const [source, setSource] = useState('video');

  const handleSourceChange = (option) => {
    if(option === 'video'){
      if ('webkitSpeechRecognition' in window) {
        setSource(option);
      }else{
        alert("this browser does not support! Please use chrome or safari");
      }
    }else{
      setSource(option);
    }
  };

    //ZQ: Add language drop-down menu 
    const [language, setLanguage] = useState("zh-CN");
    const handleLanguageChange = (event) => {
      setLanguage(event.target.value);
    };
  

  const startListening = () => {
    const speechClient = new SpeechClient("westus", language);
    speechClient.startListening(
      (text) => {
        console.log(`RECOGNIZED: ${text}`);
        setText((prevText) => prevText + text + '\n');
      },
      (error) => {
        console.log(`RECOGNITION ERROR: ${error}`);
      }
    );
    setRecognizer(speechClient);
  };

  const stopListening = () => {
    if (recognizer) {
      setRecognizer(null);
      recognizer.stopListening(() => {
        recognizer.close();
      });
    }
  };

  const startListeningVideo = () => {
    const speechClient = new SpeechClient("westus", language);
    speechClient.startListeningVideo(
      (text) => {
        console.log(`RECOGNIZED: ${text}`);
        setText((prevText) => prevText + text + '\n');
      },
      (error) => {
        console.log(`RECOGNITION ERROR: ${error}`);
      }
    );
    setRecognizer(speechClient);
  };

  const stopListeningVideo = () => {
    if (recognizer) {
      setRecognizer(null);
      recognizer.stopListeningVideo();
    }
  };

  const handleListen = () => {
    if(source === 'speech'){
      if (!recognizer) {
        setIsDisabled(true); // disable the button when listening
        startListening();
      } else {
        setIsDisabled(false);
        stopListening();
      }
    }else if(source === 'video'){
      if (!recognizer) {
        setIsDisabled(true);
        startListeningVideo();
      } else {
        setIsDisabled(false);
        stopListeningVideo();
      }
    }
  };


  const handleChatInput = (sentence) => {
    setChatInput(prevInput => prevInput + sentence + '\n');
  };

  const handleClear = () => {
    setText('');
  };

  const handleStore = () => {
    const lines = text.trim().split('\n');
    const allText = lines.join(' '); // Join all lines into one text with spaces between them
    addItemToDynamoDB({
      id: Date.now().toString(),
      text: allText,
      userId: JSON.parse(sessionStorage.getItem('user')).user_id.S, //get the userId from the session
      timestamp: Date.now().toString(),
      meetingId: sessionStorage.getItem('meetingID') //the session ensures the meetingId is same in this login period.
    });
    setText('');
    setIsStored(!isStored);
    toast.success('Meeting Saved Successfully', {
      position: toast.POSITION.TOP_RIGHT,
      hideProgressBar: true,
    });
  };

  const handleVersionChange = (event) => {
    setMeetingPageGptVersion(event.target.value);
  };

  return (
    <div id="content">
      <div className="background">
        {/* add drop-down menu */}
        <div style={{ display: 'flex'}}>
            <div style={{marginTop : '1%'}}>
              <label>Languages</label>
              <select id="language-select" value={language} onChange={handleLanguageChange}  disabled={isDisabled}>
                <option value="en-US">English</option>
                <option value="zh-CN">Mandarin</option>
                <option value="es-ES">Spanish</option>
                <option value="ar-EG">Arabic</option>
                <option value="cs-CZ">Czech</option>
                <option value="da-DK">Danish</option>
                <option value="de-DE">German</option>
                <option value="fil-PH">Filipino</option>
                <option value="fr-FR">French</option>
                <option value="ko-KR">Korean</option>
                <option value="ja-JP">Janpanese</option>
                <option value="th-TH">Thai</option>
                <option value="it-IT">Italian</option>
                <option value="vi-VN">Vietnamese</option>
                <option value="pt-BR">Portuguese</option>
              </select>
            </div>
            <div style={{marginLeft: '2%', marginTop: '1%'}}>
              <label>Voice Services</label>
              <select
              value={source}
              onChange={(e) => handleSourceChange(e.target.value)}
              >
                <option value="video">Free</option>
                <option value="speech">Premium</option>
              </select>
            </div>
            <button onClick={handleListen} className="button">
              {recognizer ? 'Stop' : 'Start Listening'}
            </button>
            <button className='button' onClick={handleStore}>Store</button>
            <div style={{ marginLeft: '2%', marginTop: '1%'}}>
              <label>GPT Version </label>
              <select id="GPTVersion-select" value={meetingPageGptVersion} onChange={handleVersionChange}>
                <option value="gpt-4">gpt-4</option>
                <option value="gpt-3.5-turbo">gpt-3.5-turbo</option>
              </select>
            </div>
            <a href = '/' className='button home-button'>Home</a>

          </div>
        <ChatButton key="all" sentences={text.split('\n')} onChatInput={handleChatInput} onClear={handleClear} setText={setText} userKey={userKey} className="chat-button" />
      </div>
      <ToastContainer />
    </div>
  );
}

export default SpeechToText;