import React, { useState } from 'react';
import { Button, Space, ConfigProvider, Dropdown } from 'antd';
import { UserOutlined, 
         SettingOutlined,
         FormOutlined,
         SketchOutlined,
         LogoutOutlined,
         ContactsOutlined,
         HomeOutlined
       } from '@ant-design/icons';
import SettingPopUpWindow from './VoiceSettingPopUpWindow';
import { useTranslation, Trans } from 'react-i18next';
import PromptDisplay from './promptUI';

const logOut = () => {
  //ILG107 set login state inlocalstorage instead of sessionstorage --Zhan Zhang
  localStorage.clear();
  sessionStorage.clear();
}

/**
 * ------------------------------------------------------
 * |            Setting Button and User Button          |
 * ------------------------------------------------------
 * @returns 
 */

function IconButtons() {

  /**
   * ------------------------------------------------------
   * |          Display Voice Setting When Click          |
   * ------------------------------------------------------
   */

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPromptModalOpen, setIsPromptModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const showPromptModal = () => {
    setIsPromptModalOpen(true);
  };

  const { t, i18n } = useTranslation();


  return (
    <div>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#394d6a'
          }
        }}
      >
        <Space wrap size={16}>
          {/* Button for prompt selection */}
          <Button type="primary" 
                  shape="round" 
                  size='middle' 
                  icon={<FormOutlined />} 
                  onClick={showPromptModal}
                   >
                  {t('Icon.Prompt')}
          </Button>

          {/* Button for voice settings */}
          <Button type="primary" 
                  shape="circle" 
                  size='middle' 
                  icon={<SettingOutlined />} 
                  onClick={showModal} />

          {/* Button for user setting */}
          <Dropdown menu={{ items: [
            {
              label: (
                <a rel="noopener noreferrer" href="/">
                  <HomeOutlined /> Return Home
                </a>
              ),
              key: '0',
            },
            {
              label: (
                <a target="_blank" rel="noopener noreferrer" href="/profile">
                  <ContactsOutlined /> {t('Icon.Profile')}
                </a>
              ),
              key: '1',
            },
            {
              label: (
                <a target="_blank" rel="noopener noreferrer" href="/premium">
                  <SketchOutlined /> {t('Icon.Premium')}
                </a>
              ),
              key: '2',
            },
            {
              label: (
                <a rel="noopener noreferrer" href="/" onClick={logOut}>
                  <LogoutOutlined /> {t('Icon.Logout')}
                </a>
              ),
              key: '3',
            },
          ] }}>
            <a onClick={(e) => e.preventDefault()}>
              <Button type="primary" shape="circle" size='large' icon={<UserOutlined />} />
            </a>
          </Dropdown>
        </Space>
      </ConfigProvider>
      <div>
        {/* pop up voice setting */}
        <SettingPopUpWindow isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
        <PromptDisplay isPromptModalOpen={isPromptModalOpen} setIsPromptModalOpen={setIsPromptModalOpen} />
      </div>
    </div>
  );
}

export default IconButtons;