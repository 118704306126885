import React, {useState} from 'react';
import "./SignupPage.css"
import Logo from '../pic/apple-touch-icon.png'; // Import the logo image file
import {signUpUser} from '.././service/userAction';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';

const validate = (values) => {
  const errors = {}
  let DOB = values.month+ '/' + values.day + '/' + values.year;
  if (!values.email) {
    errors.email = 'Required'
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address'
  }
  if (!values.year) {
    errors.DOB = 'Required'
  } else if (!/^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d$/i.test(DOB)) {
    errors.DOB = 'MM-DD-YYYY'
  }
  if (!values.firstname || !values.lastname) {
    errors.firstname = 'Required'
  } else if (!/^[a-z ,.'-]+$/i.test(values.firstname) || !/^[a-z ,.'-]+$/i.test(values.lastname) ) {
    errors.firstname = 'Invalid name'
  }
  return errors
}
function SignupPage() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname]= useState('');
    const [year,setYear] = useState('');
    const [month, setMonth] = useState('');
    const [day, setDay] = useState(''); 
    const navigate = useNavigate();

    //when user click signup
    const formik = useFormik({
      initialValues: {
        email: '',
        password: '',
        firstname: '',
        lastname: '',
        day: '',
        month: '',
        year: ''
      },
      validate,
      onSubmit: (values) => {
        try{
            const DOB = values.year+ '-'+ values.month + '-' + values.day;
            signUpUser(values.email,values.password,values.lastname, values.firstname,DOB);
            navigate('/login');
        }
        catch{}
      }
    })
    return (
      <div className='signup-container'>
        <div className="logo-container">
            <a href='/'>
              <img src={Logo} alt="IListenGPT logo"  style={{ width: '60px' }} />
            </a>
        </div>
        <h2 className='title'>Create Your Account</h2>
        <form className='form' onSubmit={formik.handleSubmit}>
          <input
            placeholder='Email'
            className='signup-input'
            type='email'
            id='email'
            value={formik.values.email}
            onBlur={formik.handleBlur}
            onChange = {formik.handleChange}
          />
          {formik.touched.email && formik.errors.email ? (
         <span className="error">{formik.errors.email}</span>) : null}
          <input
            placeholder='Password'
            className='signup-input'
            type='password'
            id='password'
            value={formik.values.password}
            onBlur={formik.handleBlur}
            onChange = {formik.handleChange}
          />
          <div className='name-container'>
            <input
              placeholder='First Name'
              className='signup-input'
              type='text'
              id='firstname'
              value={formik.values.firstname}
              onBlur={formik.handleBlur}
              onChange = {formik.handleChange}
            />
            <input
              placeholder='Last Name'
              className='signup-input'
              type='text'
              id='lastname'
              value={formik.values.lastname}
              onBlur={formik.handleBlur}
              onChange = {formik.handleChange}
            />
          </div>
          {formik.touched.firstname && formik.errors.firstname ? (
         <span className="error">{formik.errors.firstname}</span>) : null}
          <div className='DOB-container'>
            <input
              maxLength={2}
              placeholder='Mon'
              className='signup-input'
              type='text'
              id='month'
              value={formik.values.month}
              onBlur={formik.handleBlur}
              onChange = {formik.handleChange}
            />
            <input
              maxLength={2}
              placeholder='Day'
              className='signup-input'
              type='text'
              id='day'
              value={formik.values.day}
              onBlur={formik.handleBlur}
              onChange = {formik.handleChange}
            />
            <input
              maxLength={4}
              placeholder='Year'
              className='signup-input'
              type='text'
              id='year'
              value={formik.values.year}
              onBlur={formik.handleBlur}
              onChange = {formik.handleChange}
            />
          </div>
          {(formik.touched.year && formik.touched.month && formik.touched.day && formik.errors.DOB) ? (
            <span className="error">{formik.errors.DOB}</span>) : null}
          <div className='signup-text'>
                Already have an account? <a href="/login">Log in</a>
          </div>
          <button className='signup-button' type="submit">Sign Up</button>
        </form>
      </div>
    );
  }
  
  export default SignupPage;