import React, { useContext, useState,useEffect } from 'react';
import { Modal, Button, Select, Divider, message} from 'antd';
import dataContext from '../../dataContext';
import { useTranslation, Trans } from 'react-i18next';


function SettingPopUpWindow(props) {


  const [isPremiumUser, setIsPremiumUser] = useState('');
  const { language, setLanguage, gptVersion, setGPTVersion, userKey, messageRemaining } = useContext(dataContext);
  const [MainLanguage, setMainLanguage] = useState('English');
  const [source, setSource] = useState('video');
  const { t, i18n } = useTranslation();

  const userstring = localStorage.getItem('user');
  const user = JSON.parse(userstring);

  useEffect(() => {
    setIsPremiumUser(user.premium_user.BOOL);
}, []);


  console.log(user);
  

  const handleMainLanguage = (value) => {
    setMainLanguage(value);
    i18n.changeLanguage(value);
  }


  const handleLanguageChange = (value) => {
    console.log(`Language selected ${value}`);
    setLanguage(value);
  };


  const handleVersionChange = (value) => {
    console.log(isPremiumUser);
    if (value === 'speech') {
        if (!isPremiumUser) {
            message.error("Non-premium users can't select the Premium voice version.");
            return; // Don't update the state if the user is not premium and selects 'Premium'
        }
    }
    console.log(`Voice Version selected ${value}`);
    setSource(value);
  };

  const handleGptVersionChange = (value) => {
    console.log(`GPT version selected ${value}`);
    setGPTVersion(value);
  };

  function Language() {
    return (
      <div>
        <h3>{t('Setting.Main_Language')}</h3>
        <Select
          defaultValue={i18n.language}
          onChange={handleMainLanguage}
          value = {i18n.language}
          options={[
            { value: 'en', label: 'English' },
            { value: 'cn', label: 'Chinese' },
          ]}
        />
        <Divider />
      </div>
    );
  }

  function VoiceVersion() {
    return (
      <div>
        <h3>{t('Setting.Voice_Version')}</h3>
        <Select
          defaultValue="Default"
          style={{ width: 120 }}
          onChange={handleVersionChange}
          value={source}
        >
          <Select.Option value="video">Default</Select.Option>
          <Select.Option value="speech">Premium</Select.Option>
        </Select>
        <Divider />
      </div>
    );
}


  function VoiceLanguage() {
    return (
      <div>
        <h3>{t('Setting.Language')}</h3>
        <Select
          defaultValue="en-US"
          style={{ width: 120 }}
          onChange={handleLanguageChange}
          value={language}
          options={[
            { value: "en-US", label: 'English' },
            { value: "zh-CN", label: 'Mandarin' },
            { value: "es-ES", label: 'Spanish' },
            { value: "ar-EG", label: 'Arabic' },
            { value: "cs-CZ", label: 'Czech' },
            { value: "da-DK", label: 'Danish' },
            { value: "de-DE", label: 'German' },
            { value: "fil-PH", label: 'Filipino' },
            { value: "fr-FR", label: 'French' },
            { value: "ko-KR", label: 'Korean' },
            { value: "ja-JP", label: 'Japanese' },
            { value: "th-TH", label: 'Thai' },
            { value: "it-IT", label: 'Italian' },
            { value: "vi-VN", label: 'Vietnamese' },
            { value: "pt-BR", label: 'Portuguese' }
          ]}
        />
        <Divider />
      </div>
    );
  }

  function GPTVersion() {
    return (
      <div>
        <h3>Voice Reconizer Version</h3>
        <Select
          defaultValue="gpt-3.5-turbo"
          style={{ width: 120 }}
          onChange={handleGptVersionChange}
          value={gptVersion}
          options={[
            { value: 'gpt-3.5-turbo', label: 'gpt-3.5' },
            { value: 'gpt-4', label: 'gpt-4' },
          ]}
        />
        {
          (gptVersion === 'gpt-4') ?
          <p className="message-container">You have {messageRemaining.current} messages left.</p>
          : <></>
        }
        <Divider />
      </div>
    );
  }

  return (
    <Modal title={t('Setting.Setting')}
           open={props.isModalOpen} 
           onCancel={() => props.setIsModalOpen(false)}
           footer={
            <Button key="ok" type="primary" onClick={() => props.setIsModalOpen(false)}>
              OK
            </Button>
           } >
      <Language />
      <VoiceVersion />
      <VoiceLanguage />
      <GPTVersion />
    </Modal>
  );
}

export default SettingPopUpWindow;

