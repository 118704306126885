import { dynamodb } from "./dynamoClient.js";

export function addItemToPromptTable(item) {
  const params = {
    TableName: "user_prompt",
    Item: {
      id: { S: Math.random().toString(36).substring(7) },
      user_id: { S: item.userId || "" },
      category: { S: item.category },
      created_at: { N: Date.now().toString() },
      prompt_name: { S: item.name },
      prompt_text: { S: item.prompt },
      is_deleted: { N: "0" },
    },
  };

  return new Promise((resolve, reject) => {
    dynamodb.putItem(params, function (err, data) {
      if (err) {
        console.error(err);
        reject(err);
      } else {
        console.log(data);
        resolve(data);
      }
    });
  });
}

export function retrieveFromPromptTable(userID) {
  const params = {
    TableName: "user_prompt",
    ExpressionAttributeValues: {
      ":user_id": { S: userID },
      ":default_id": { S: "default" },
      ":is_deleted": { N: "0" },
    },
    FilterExpression:
      "(user_id = :user_id OR user_id = :default_id) AND is_deleted = :is_deleted",
  };

  return new Promise((resolve, reject) => {
    dynamodb.scan(params, function (err, data) {
      if (err) {
        reject(err);
      } else {
        resolve(data);
      }
    });
  });
}

export function deleteFromPromptTable(primaryKey, user_id) {
  const param = {
    TableName: "user_prompt",
    Key: {
      id: { S: primaryKey }, // The update operation only supports the primary key.
      user_id: { S: user_id },
    },
    UpdateExpression: "SET is_deleted = :deletedValue",
    ExpressionAttributeValues: {
      ":deletedValue": { N: "1" }, // Assuming is_deleted is a Number attribute
    },
  };

  return new Promise((resolve, reject) => {
    dynamodb.updateItem(param, function (err, data) {
      if (err) {
        console.error("Error: " + err);
        reject(err);
      } else {
        resolve(data);
      }
    });
  });
}

export function updateItemFromPromptTable(user_id, newPrompt) {
  const param = {
    TableName: "user_prompt",
    Key: {
      id: { S: newPrompt.id },
      user_id: { S: user_id },
    },
    UpdateExpression:
      "SET prompt_name = :prompt_name, prompt_text = :prompt_text",
    ExpressionAttributeValues: {
      ":prompt_name": { S: newPrompt.name },
      ":prompt_text": { S: newPrompt.text },
    },
  };

  return new Promise((resolve, reject) => {
    dynamodb.updateItem(param, function (err, data) {
      if (err) {
        console.error("Error: " + err);
        reject(err);
      } else {
        resolve(data);
      }
    });
  });
}
