import React, { useState } from 'react';
import {
  Typography,
  Card,
  CardContent,
  Button,
  TextField,
  Grid,
  Tooltip,
  Divider,
} from '@mui/material';
import {updatepremium} from '../clients/user';

const UpgradePlan = () => {
  const [numAccounts, setNumAccounts] = useState(1);
  const [nameOnCard, setnameOnCard] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [expires, setExpires] = useState('');
  const [cvv, setCVV] = useState('');
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);


  const userInfo = localStorage.getItem('user');
  const userData = JSON.parse(userInfo);
  const userEmail = userData.email.S;
  const userId = userData.user_id.S;


  const handleNumAccountsChange = (value) => {
    // Add validation if needed
    setNumAccounts(value);
  };


  const handlePayWithCardClick = () => {
    // Add logic for processing payment with card details
    console.log('Processing payment with card details');
  };

  return (
    <div style={{ backgroundColor: 'rgb(250, 250, 250)', padding: '60px 20%' }}>
      <Typography variant="h3" gutterBottom style={{ textAlign: 'center', padding: '20px'}}>
        Upgrade your plan
      </Typography>

      <Typography variant="body1" gutterBottom style={{ textAlign: 'center', color:'grey'}}>
        Account: {userEmail}
      </Typography>

      {/* Plan Details Card */}
      <Card style={{ margin: '10px' }}>
  <CardContent>
    <Typography variant="h6" gutterBottom style={{ display: 'flex', justifyContent: 'space-between' }}>
        <span><b>Pro Plan</b></span>
        <span style={{ marginRight: '10px' }}> <b>US$15</b> /account/month</span>
    </Typography>

    <Grid container spacing={2}>
        <Grid item xs={12} container justifyContent="space-between" alignItems="center">
        <Typography variant="body1"><b>Num. of Accounts</b></Typography>
            <div>
                <Button onClick={() => handleNumAccountsChange(Math.max(1, numAccounts - 1))}>
                -
                </Button>
                {numAccounts}
                <Button onClick={() => handleNumAccountsChange(numAccounts + 1)}>
                +
                </Button>
            </div>
        </Grid>
      <Divider variant="middle" sx={{ backgroundColor: 'black', margin: '10px 0' }} />
      <Grid item xs={12} container justifyContent="space-between" alignItems="center" style={{ margin: '15px 0 0 0' }}>
        
        <Typography variant="h6"><b>Total</b></Typography>
        <Typography variant="h6" style={{ marginRight: '20px' }}><b>${(15 * numAccounts).toFixed(2)}</b></Typography>
      </Grid>
    </Grid>
  </CardContent>
</Card>

      {/* Payment Card */}
      <Card style={{ margin: '10px'}}>
      <CardContent>
        <Typography variant="h6" gutterBottom style={{ margin: '10px 0'}}>
            <b>PAY WITH CARD</b>
        </Typography>
        <Grid container spacing={1}>
            <Grid item xs={12}>
            <TextField
                label="Name on Card"
                variant="outlined"
                fullWidth
                value={nameOnCard}
                onChange={(e) => setnameOnCard(e.target.value)}
            />
            </Grid>
            <Grid item xs={7}>
            <TextField
                label="Card Number"
                variant="outlined"
                fullWidth
                value={cardNumber}
                onChange={(e) => setCardNumber(e.target.value)}
            />
            </Grid>
            <Grid item xs={3}>
            <TextField
                label="Expires"
                variant="outlined"
                fullWidth
                value={expires}
                onChange={(e) => setExpires(e.target.value)}
            />
            </Grid>
            <Grid item xs={2}>
                <Tooltip
                title="Last three digits on signature strip"
                arrow
                open={isTooltipOpen}
                onClose={() => setIsTooltipOpen(false)}
            >
                <TextField
                label="CVV"
                variant="outlined"
                fullWidth
                value={cvv}
                onChange={(e) => setCVV(e.target.value)}
                onFocus={() => setIsTooltipOpen(true)}
                onBlur={() => setIsTooltipOpen(false)}
                />
            </Tooltip>
            </Grid>
        </Grid>
        </CardContent>
        <div style={{ display: 'flex', justifyContent: 'right' }}>
            <a href="/premium">
            <Button onClick={handlePayWithCardClick} variant="contained" style={{ margin: '15px 5px',color: 'white', backgroundColor: 'gray'}}>
                 BACK
            </Button>
            </a>
            <Button  onClick={()=>{
                          updatepremium(userId,()=>{
                            alert("You upgraded successfully.");
                            window.location.href = '/';
                          },()=>{
                            alert("Your upgrade failed.");
                          });
                        
                      }} variant="contained" style={{ margin: '15px'}}>
                 Pay with Card
            </Button>
        </div>
      </Card>


    </div>
  );
};

export default UpgradePlan;