import React, { useContext, useRef } from 'react';
import dataContext from '../../dataContext';
import './ChatWindow.css'
import { Divider, ConfigProvider } from 'antd';
import ReactMarkdown from 'react-markdown';

/**
 * ------------------------------------------------------
 * |                  Response Viewer                   |
 * ------------------------------------------------------
 * @param {*} props 
 * @returns 
 */

function ChatWindow() {
  const { responses, selectBarCollapsed, historyBarCollapsed } = useContext(dataContext);
  const responseContainerRef = useRef(null);

  return (
    <div ref={responseContainerRef} 
         className={!selectBarCollapsed && !historyBarCollapsed ? 
         'chatContainer chatContainer-padding-adjust' : 'chatContainer'} >
      {responses.length > 0 ? (
        responses.map((response, index) => (
          <div key={index}>
            {response.role == 'user' ? 
              <p>
                <span className='img'>
                  <img src={require('../../pic/user-account-avatar-32x32.png')}
                      alt="Your Image"
                      width="25"
                      height="25"/>
                </span> 
                &nbsp;&nbsp; :
                {response.content}
              </p>
              :
              <p>
                <span className='img'>
                  <img src={require('../../pic/chatgpt-32x32.png')} 
                       alt="Your Image" 
                       width="25" 
                       height="25"/>
                </span> 
                &nbsp;&nbsp; : 
                <ReactMarkdown>{response.content}</ReactMarkdown>
              </p>
            }
            <ConfigProvider
              theme={{
                token: {
                  colorSplit: '#3a506b',
                },
              }}>
                <Divider className='divider' />
            </ConfigProvider>
          </div>
        ))
      ) : (
        <p className='custom-text'>IListenGPT</p>
      )}
    </div>
  );
}

export default ChatWindow;