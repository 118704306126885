import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import GlobalStyles from '@mui/material/GlobalStyles';
import Container from '@mui/material/Container';
import "./Premium.css";

import {updatepremium} from '../clients/user';
import { useTranslation, Trans } from 'react-i18next';


const tiers = [
  {
    title: 'Free',
    price: '0',
    description: [
      'FreeDesc1',
      'FreeDesc2',
      'FreeDesc3',
      'FreeDesc4',
    ],
    buttonText: 'buttonText',
    buttonVariant: 'outlined',
    link: '',
  },
  {
    title: 'Pro',
    price: '15',
    description: [
      'GPT4Unlimited',
      'MicrosoftVoiceService',
      'MoreLanguageSelection',
      'LongTextListeningAndSummaryProcessing',
    ],
    buttonText: 'GetStarted',
    buttonVariant: 'contained',
    link:'/precheckout',
  },
  {
    title: 'Enterprise',
    price: '30',
    description: [
      'EnterpriseDesc1',
      'EnterpriseDesc2',
      'EnterpriseDesc3',
      'EnterpriseDesc4',
    ],
    buttonText: 'ContactUs',
    buttonVariant: 'outlined',
    link: '',
  },
];




const defaultTheme = createTheme();

export default function Premium() {

  const userInfo = localStorage.getItem('user');
  const userData = JSON.parse(userInfo);
  const userId = userData.user_id.S;
  const { t, i18n } = useTranslation();



  return (
    <ThemeProvider theme={defaultTheme} >
      <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
      <CssBaseline />

      {/* Hero unit */}
      <Container disableGutters maxWidth="sm" component="main" sx={{ pt: 8, pb: 6 }}>
        <Typography
          component="h4"
          variant="h4"
          align="center"
          color="text.primary"
          gutterBottom
        >
          <b>Pick a plan that suits you needs </b>
        </Typography>
        <Typography variant="h6" align="center" color="text.secondary" component="p"> We offer several types of packages.
        </Typography>
      </Container>
      {/* End hero unit */}
      <Container maxWidth="md" component="main">
        <Grid container spacing={5} alignItems="flex-end">
          {tiers.map((tier) => (
            <Grid
              item
              key={tier.title}
              xs={12}
              sm={tier.title === 'Enterprise' ? 12 : 6}
              md={4}
            >
              <Card>
                <CardHeader 
                  title= {t(`premium.${tier.title}`)}
                  subheader={tier.subheader}
                  titleTypographyProps={{ align: 'center' }}
                  action={tier.title === 'Pro' }
                  subheaderTypographyProps={{
                    align: 'center',
                  }}
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.mode === 'light'
                        ? theme.palette.grey[200]
                        : theme.palette.grey[700],
                  }}
                />
                <CardContent>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'baseline',
                      mb: 2,
                    }}
                  >
                    <Typography component="h2" variant="h3" color="text.primary">
                      ${tier.price}
                    </Typography>
                    <Typography variant="h6" color="text.secondary">
                        /month
                    </Typography>
                  </Box>
                  <ul>
                    {tier.description.map((line) => (
                      <Typography
                        component="li"
                        variant="subtitle1"
                        align="center"
                        key={line}
                      >
                        {t(`premium.${line}`)}
                      </Typography>
                    ))}
                  </ul>
                </CardContent>
                <CardActions>
                  <a href={tier.link}>
                    <Button className='cardbtn'  variant={tier.buttonVariant}>
                        {tier.buttonText}
                    </Button>
                  </a>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
      {/* Footer */}
      <Container
        maxWidth="md"
        component="footer"
        sx={{
          mt: 8,
          py: [3, 6],
        }}
      >
      </Container>
      {/* End footer */}
    </ThemeProvider>
  );
}