import React from 'react';
import { enquireScreen } from 'enquire-js';
import Banner from './Banner';
import Features from './Features';



let isMobile;

enquireScreen((b) => {
  isMobile = b;
});

class Home extends React.PureComponent {
  state = {
    isMobile,
  }
  componentDidMount() {
    enquireScreen((b) => {
      this.setState({
        isMobile: !!b,
      });
    });
  }
  render() {
    return (

          <div className="home-wrapper">
            <Banner isMobile={this.state.isMobile} />
            <Features isMobile={this.state.isMobile} />
          </div>
    );
  }
}

export default Home;
