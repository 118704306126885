import React, { useState, useEffect} from 'react';
import Header from '../component/Header';
import Footer from '../component/Footer';
import { useLocation } from 'react-router-dom';
import Banner from '../component/Home';

function Homepage() {
  const location = useLocation();
  const [isLoggedIn,setisLoggedIn] = useState(false)
  const [name, setName] = useState('');
  

  const[userId,setUserId]=useState('');

  useEffect(() =>{
    //ILG107 set login state inlocalstorage instead of sessionstorage --Zhan Zhang
    const userstring = localStorage.getItem('user');
    const user = JSON.parse(userstring);
    if(user != null){
      setName(user.full_name.S)
      const userIdString = JSON.stringify(user.user_id)
      setUserId(userIdString)
    }
    
  },[])

  useEffect(()=>{
    //ILG107 set login state inlocalstorage instead of sessionstorage --Zhan Zhang
    const checkIsLoggedIN = localStorage.getItem('LoggedIn');
    if(checkIsLoggedIN){
      setisLoggedIn(true)
    }
  })


//determine which browser the user is on
  useEffect(()=>{
    const brower = getBrowser();
    console.log('User is using', brower);
    if(brower==='Unknown'){
      showBrowserNotSupportMessage();
    }
  },[]);

  function getBrowser(){
    const userAgent = navigator.userAgent;
    if(userAgent.indexOf('Chrome')!==-1 && userAgent.indexOf('Edge') === -1 && userAgent.indexOf('OPR') === -1){
      return 'Chrome';
    } else if (userAgent.indexOf('Safari') !== -1 && userAgent.indexOf('Chrome') === -1 && userAgent.indexOf('Edge') === -1 && userAgent.indexOf('OPR') === -1) {
      return 'Safari';
    } else if ((userAgent.indexOf('Edge') !== -1 || userAgent.indexOf('Edg') !== -1) && userAgent.indexOf('Chrome') === -1) {
      return 'Microsoft Edge';
    } else if (userAgent.indexOf('OPR') !== -1 && userAgent.indexOf('Chrome') === -1 && userAgent.indexOf('Edge') === -1) {
      return 'Opera';
    } else if (userAgent.indexOf('Firefox') !== -1) {
      return 'Firefox';
    } else {
      return 'Unknown';
    }
  }

  function showBrowserNotSupportMessage(){
    const message ='Your browser may not support our voice services. Please switch to another browser, such as the latest version of Chrome or Safari.';
    alert(message);
  }


  return (
    <div style={{ padding: '1rem' }}>
      <Header name = {name} LoggedIn = {isLoggedIn} userId={userId}/>
      <Banner />
      <Footer />
    </div>
  );
}

export default Homepage;
