import { SpeechRecognizer, SpeechConfig, AudioConfig, ResultReason } from 'microsoft-cognitiveservices-speech-sdk';

const subscriptionKey = process.env.REACT_APP_MICROSOFT_SPEECH_KEY

export default class SpeechClient {
  constructor(serviceRegion, recognitionLanguage) {
    this.speechConfig = SpeechConfig.fromSubscription(subscriptionKey, serviceRegion);
    this.speechConfig.speechRecognitionLanguage = recognitionLanguage;
    this.recognizer = new SpeechRecognizer(this.speechConfig);
  }

  recognition = new window.webkitSpeechRecognition();

  startListeningVideo(onRecognized, onError, soundDetector) {

    this.recognition.continuous = true;
    this.recognition.interimResults = false;
    this.recognition.lang = this.speechConfig.speechRecognitionLanguage;
    
    this.recognition.onsoundstart = () => {
      soundDetector.current = true;
    };

    this.recognition.onsoundend = () => {
      soundDetector.current = false;
    };

    this.recognition.onresult = (event) => {
      // Iterate through the results
      for (let i = event.resultIndex; i < event.results.length; ++i) {
        // Check if the result is final
        if (event.results[i].isFinal) {
          const transcript = event.results[i][0].transcript;
          onRecognized(transcript);
        }
      }
    };

    this.recognition.onerror = (event) => {
      console.error('Speech recognition error:', event.error);
      onError(event.error);
    };

    this.recognition.onend = () => {
      console.log('Speech recognition ended.');
    };

    this.recognition.start();
  }

  stopListeningVideo(soundDetector){
    soundDetector.current = false;
    this.recognition.stop();
  }

  startListening(onRecognized, onError, soundDetector){
    this.recognizer.recognized = (s, e) => {
      if (e.result.reason === ResultReason.RecognizedSpeech) {
        soundDetector.current = true;
        onRecognized(e.result.text);
      } else {
        onError(e.result.reason);
      }
    };
    this.recognizer.startContinuousRecognitionAsync();
  }

  stopListening(onStopped, soundDetector) {
    this.recognizer.stopContinuousRecognitionAsync(() => {
      this.recognizer.close();
      onStopped();
    });
    soundDetector.current = false;
  }
}

