import { dynamodb } from "./dynamoClient.js";

// 获取所有表名
const listTables = async () => {
  const tables = await dynamodb.listTables({}).promise();
  return tables.TableNames;
};

// 从一个表中获取所有项目
const scanTable = async (tableName) => {
  const params = {
      TableName: tableName,
  };
  const scanResults = [];
  let items;
  do {
      items = await dynamodb.scan(params).promise();
      items.Items.forEach((item) => scanResults.push(item));
      params.ExclusiveStartKey = items.LastEvaluatedKey;
  } while (typeof items.LastEvaluatedKey !== 'undefined');

  return scanResults;
};


// 主函数
export async function main(){
  const tableNames = await listTables();
  const results = {};
  for (const tableName of tableNames) {
      const items = await scanTable(tableName);
      results[tableName] = items;
  }
  return results;
};

