import React, { useRef, useEffect, useState } from 'react';
import * as d3 from 'd3';
import { main } from "../clients/getalldata";

function Createbarchart() {
    return (
        <div>
            <BarChart />
        </div>
    );
}

function BarChart() {
    const premiumRef = useRef();
    const normalRef = useRef();
    const pieChartRef = useRef();

    const [tableDataSourceMap, setTableDataSourceMap] = useState(new Map());
    const [users, setUsers] = useState([]);
    const [userCountByMonthYear, setUserCountByMonthYear] = useState(new Map());
    const [premiumuserTableData, setPremiumUserTableData] = useState([]);
    
    const [normaluserTableData, setnormalUserTableData] = useState();
    const [normalUserCountByMonthYear, setNormalUserCountByMonthYear] = useState(new Map());

    const [pieChartData, setPieChartData] = useState([]);
    useEffect(() => {
        
        const getAllTables = async () => {
            try {
                const results = await main();
                await buildMap(results);
                
            } catch (error) {
                console.error("Error:", error);
            }
        };
        
        getAllTables();
    }, []);

    const getUserCountByMonthYear = () => {
        let newuserCountByMonthYear = new Map();
        let newnormalUserCountByMonthYear = new Map();
        users.forEach(user => {
        
            if(user.premium_user){
                let timestamp = parseInt(user.created_at);
                if(!isNaN(timestamp)) {
                    let date = new Date(timestamp);
                    let monthYear = date.getFullYear() + '-' + String(date.getMonth() + 1).padStart(2, '0');
                
                if (newuserCountByMonthYear.has(monthYear)) {
                    newuserCountByMonthYear.set(monthYear, newuserCountByMonthYear.get(monthYear) + 1);
                } else {
                    newuserCountByMonthYear.set(monthYear, 1);
                }
                }
            }else{
                let timestamp = parseInt(user.created_at);
                if(!isNaN(timestamp)) {
                    let date = new Date(timestamp);
                    let monthYear = date.getFullYear() + '-' + String(date.getMonth() + 1).padStart(2, '0');
                
                if (newnormalUserCountByMonthYear.has(monthYear)) {
                    newnormalUserCountByMonthYear.set(monthYear, newnormalUserCountByMonthYear.get(monthYear) + 1);
                } else {
                    newnormalUserCountByMonthYear.set(monthYear, 1);
                }
                }
            }
        });
        setNormalUserCountByMonthYear(newnormalUserCountByMonthYear);
        setUserCountByMonthYear(newuserCountByMonthYear);
    };

    const buildMap = async (myObject) => {
        
        const newTableDataSourceMap = new Map();

        for (let key in myObject) {
            if (myObject.hasOwnProperty(key)) {
                const transformedData = myObject[key].map(item => parseDynamoDBItem(item));
                newTableDataSourceMap.set(key, transformedData);
            }
        }
        setTableDataSourceMap(newTableDataSourceMap);

    };
    useEffect(() => {
        getUserCountByMonthYear();
    }, [users]);

    useEffect(() => {
        const data = Array.from(userCountByMonthYear, ([date, value]) => ({ date, value }));
        data.sort((a, b) => {
            // 比较日期字符串
            return a.date.localeCompare(b.date);
        });
        setPremiumUserTableData(data);
    }, [userCountByMonthYear]);

    useEffect(() => {
        const data = Array.from(normalUserCountByMonthYear, ([date, value]) => ({ date, value }));
        data.sort((a, b) => {
            // 比较日期字符串
            return a.date.localeCompare(b.date);
        });
        setnormalUserTableData(data);
    }, [normalUserCountByMonthYear]);

    useEffect(() => {
        // 确保数据不为空且已准备好
        if (premiumuserTableData && premiumuserTableData.length > 0) {
            createBarChart(premiumuserTableData, premiumRef,"Premium User Growth");
        }
    }, [premiumuserTableData]);
    
    useEffect(() => {
        // 确保数据不为空且已准备好
        if (normaluserTableData && normaluserTableData.length > 0) {
            createBarChart(normaluserTableData, normalRef, "Normal User Growth");
        }
    }, [normaluserTableData]);
    
    useEffect(() => {
        
        if (tableDataSourceMap.has('user')) {
            setUsers(tableDataSourceMap.get('user'));
            console.log(tableDataSourceMap);
            const n = tableDataSourceMap.get('user').length;
            // 获取去重后的大小
            const uniqueNames = new Set(tableDataSourceMap.get('user_prompt').map(item => item.user_id));
            const m = uniqueNames.size;
            setPieChartData([
                { key: 'With Prompt', value: m },
                { key: 'Without Prompt', value: n },
              ]);
        }
    }, [tableDataSourceMap]);

    useEffect(() =>{
        if (pieChartData && pieChartData.length > 0) {
            createPieChart(pieChartData, pieChartRef);
        }
    })

    const parseDynamoDBItem = (item) => {
        const parsedItem = {};
        for (const key in item) {
            if (item[key].S !== undefined) {
                parsedItem[key] = item[key].S;
            } else if (item[key].N !== undefined) {
                parsedItem[key] = Number(item[key].N);
            } else if (item[key].BOOL !== undefined) {
                parsedItem[key] = item[key].BOOL ? true : false;
            }
        }
        return parsedItem;
    };


    function createPieChart(data, ref) {
        d3.select(ref.current).select("svg").remove();
        const width = 1000, height = 450, margin = 40;
        const radius = Math.min(width, height) / 2 - margin;
    
        const svg = d3.select(ref.current)
            .append("svg")
                .attr("width", width)
                .attr("height", height)
            .append("g")
                .attr("transform", `translate(${width / 2}, ${height / 2})`);
    
        const color = d3.scaleOrdinal()
            .domain(data.map(d => d.key))
            .range(d3.schemeCategory10);
    
        const pie = d3.pie()
            .value(d => d.value);
    
        const arc = d3.arc()
            .innerRadius(radius*0.6)
            .outerRadius(radius); 
        

        const outerArcForLabelsPosition = d3.arc().
        innerRadius(radius*0.9)
        .outerRadius(radius*0.9);

        // 使用 arc 的 centroid 函数来定位文本
        const textArc = d3.arc()
            .innerRadius(radius * 0.7)
            .outerRadius(radius * 0.7);
    
        const pieData = pie(data);
    
        svg.selectAll('path')
            .data(pieData)
            .enter()
            .append('path')
                .attr('fill', d => color(d.data.key))
                .attr('d', arc);
    
        svg.selectAll('.label') // Use a class name like '.label' for selection
            .data(pieData)
            .enter()
            .append('text')
            .attr("class", "label") // Assign a class for styling and future selections
            .attr("transform", (d) => {
                const pos = outerArcForLabelsPosition.centroid(d);
                const midAngle = d.startAngle + (d.endAngle - d.startAngle) / 2;
                pos[0] = radius * 0.99 * (midAngle < Math.PI ? 1 : -1);
                return `translate(${pos})`;
            })
            .attr("text-anchor", (d) => {
                const midAngle = d.startAngle + (d.endAngle - d.startAngle) / 2;
                return (midAngle < Math.PI ? 'start' : 'end');
            })
            .text(d => `Users ${d.data.key}: ${d.data.value}`) // Ensure data structure has 'key' and 'value'
            .style("font-size", "20px")
            .style("fill", "black")
            .style("font-family", "Helvetica Neue")
            .style("font-weight","bold")
            ;

        svg.selectAll('allPolylines')
        .data(pieData)
        .enter()
        .append('polyline') // Changed 'Polyline' to 'polyline'
        .attr('stroke', 'black')
        .attr('fill', 'none')
        .attr('stroke-width', 2) // Removed 'px', although '1px' is also valid
        .attr('points', (d) => {
            const posA = arc.centroid(d);
            const posB = outerArcForLabelsPosition.centroid(d);
            const posC = outerArcForLabelsPosition.centroid(d);
            const midAngle = d.startAngle + (d.endAngle - d.startAngle)/2;
            posC[0] = radius * 0.95 * (midAngle < Math.PI ? 1 : -1); 

            // Flattening the array and converting it to a string
            return [posA, posB, posC].map(point => point.join(',')).join(' ');
        });

           // Add title
    svg.append("text")
        .attr("class", "chart-title") // Assign a class for styling
        .attr("x", 0) // Centered on the X axis
        .attr("y", -height/2+margin/2) // Positioned at the top of the chart
        .attr("text-anchor", "middle") // Center the text
        .style("font-size", "24px")
        .style("font-weight","bold")
        .style("fill", "black")
        .style("font-family", "Helvetica Neue")
        .text("Users With and Without Prompt"); // Replace with your title


    }
    

    const createBarChart = (data, ref, title) => {
        // (Your D3 code to create the bar chart)
        d3.select(ref.current).select("svg").remove();
        // Set the dimensions and margins of the graph
        const margin = { top: 50, right: 30, bottom: 70, left: 60 },
                width = 800 - margin.left - margin.right,
                height = 600 - margin.top - margin.bottom;
        
        // Append the svg object to the div called 'bar-chart'
        const svg = d3.select(ref.current)
            .append("svg")
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .append("g")
            .attr("transform", `translate(${margin.left},${margin.top})`);
        
        // X axis
        const x = d3.scaleBand()
            .range([ 0, width ])
            .domain(data.map(d => d.date))
            .padding(0.2);
        
        svg.append("g")
            .attr("transform", `translate(0,${height})`)
            .call(d3.axisBottom(x))
            .selectAll("text")
            .attr("transform", "translate(-10,0)rotate(-45)")
            .style("text-anchor", "end")
            .style("font-family", "Arial") // Example: Change font-family
            .style("font-weight","bold")
            .style("font-size", "12px");
        
        // Add Y axis
        const y = d3.scaleLinear()
            .domain([0, d3.max(data, d => d.value)])
            .range([ height, 0]);
        
        svg.append("g")
            .call(d3.axisLeft(y))
            .style("font-family", "Arial") // Example: Change font-family
            .style("font-weight","bold")
            .style("font-size", "12px");
        
        // Optional: Add title
        svg.append("text")
            .attr("x", (width / 2))             
            .attr("y", 0 - (margin.top)/2)
            .attr("text-anchor", "middle")  
            .style("font-size", "24px") 
            .style("font-weight","bold")
            .style("fill","#90BEDE")
            .text(title);
    
        svg.selectAll("mybar")
            .data(data)
            .join("rect")
            .attr("x", d => x(d.date))
            .attr("y", d => y(0)) // Start at height 0
            .attr("width", x.bandwidth())
            .attr("height", 0) // Initial height set to 0
            .attr("fill", "#69b3a2")
            .transition() // Add transition
            .duration(800) // Duration of the animation in milliseconds
            .attr("y", d => y(d.value)) // End at the actual height
            .attr("height", d => height - y(d.value)); // Transition to actual height
    
        // Define the line generator
        const line = d3.line()
        .x(d => x(d.date)+x.bandwidth() / 2)
        .y(d => y(d.value));
    
        // Append the line to the SVG
        svg.append("path")
            .datum(data)
            .attr("fill", "none")
            .attr("stroke", "#5FBDFF")
            .attr("stroke-width", 3)
            .attr("d", line);
        
        // Append text on each bar
        svg.selectAll(".bar-text")
        .data(data)
        .enter()
        .append("text")
        .attr("class", "bar-text")
        .attr("x", d => x(d.date) + x.bandwidth() / 2) // Position in the middle of the bar
        .attr("y", d => y(d.value) - 5) // Position slightly above the bar
        .attr("text-anchor", "middle") // Center the text
        .text(d => d.value) // Set the text to user count
        .style("font-size", "20px")
        .style("fill", "#7B66FF")
        .style("font-weight", "bold");
    };


    return (

<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start', height: '100vh', paddingTop: '20px' }}>
    
    {/* Pie Chart */}
    <div style={{ width: 'fit-content', marginBottom: '20px' }}>
        <div ref={pieChartRef}/>
    </div>

    {/* Bar Charts */}
    <div style={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
        <div ref={premiumRef} style={{ flex: 1 }} />
        <div ref={normalRef} style={{ flex: 1 }} />
    </div>
    
</div>



    ); 
}


export default Createbarchart;