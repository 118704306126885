import React, { useState, useContext } from 'react';
import "./LoginPage.css"
import Logo from '../pic/apple-touch-icon.png'; // Import the logo image file
import {logInUser} from '.././service/userAction';
import { useNavigate } from 'react-router-dom';
import { LoginContext } from '../App';
import GoogleOauth from '../service/googleOAuth';


function LoginPage() {
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    
    const handleLogin = (e) => {
        e.preventDefault();
    
        logInUser(username, password)
          .then((result) => {
            if (result != null) {
              // Handle successful login'
              //ILG107 set login state inlocalstorage instead of sessionstorage --Zhan Zhang
              localStorage.setItem('LoggedIn', 'true');
              localStorage.setItem('user', JSON.stringify(result));
              

              /**
               *  This meetingID serves as a unique identifier for a single-time login session,
               *  and will be utilized to record data during this particular session.
               *  The meetingID will be changed when user opens a new meeting.
               */
              localStorage.setItem('meetingID', Math.random().toString(36).substring(7));
              
              
              window.location.href = '/';
              

            } else {
              // Handle login failure
              console.log('Login failed');
            }
          })
      };

    const handleGoogleLogin = ((user) => {
      //ILG107 set login state inlocalstorage instead of sessionstorage --Zhan Zhang
      localStorage.setItem('LoggedIn', 'true');
      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('meetingID', Math.random().toString(36).substring(7));
      navigate('/');
      
    });

      return (
          <div className="login-container">
            <div className="logo-container">
              <a href='/'>
                <img src={Logo} alt="IListenGPT logo"  style={{ width: '60px' }} />
              </a>
            </div>
            <h2 className='title'>Welcome Back!</h2>
            <form className='form' onSubmit={handleLogin}>
              <input
                placeholder='Username'
                className='login-input'
                type="text"
                id="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <input
                placeholder='Password'
                className='login-input'
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <div className='login-text'>
                Don't have an account? <a href="/signup">Sign up</a>
              </div>
              <button className='login-button' type="submit">Log In</button>
              <h3>               or               </h3>
              <GoogleOauth onSubmit={handleGoogleLogin}/>
            </form>
          </div>
        );
  }

  export default LoginPage;