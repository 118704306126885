import React from 'react';
import PropTypes from 'prop-types';
import QueueAnim from 'rc-queue-anim';
import TweenOne from 'rc-tween-one';
import { Button } from 'antd';
import banner from '../../pic/chatbot.svg';
import './Banner.css';
import { Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';

function Banner(props) {
  const { t, i18n } = useTranslation();

  let content = (
    <React.Fragment>
      <h1 key="h1">{t("Home.IListenGPT")}</h1>
      <p key="content">
        Effortlessly convert your voice into written text and chat with GPT in real time with our speech-to-text product.
      </p>
      <div key="button" className="button-wrapper">
        <a href="#" target="_blank" rel="noopener noreferrer">
          <Link to="/Interview">
            <Button type="primary">
              Interview Service
            </Button>
          </Link>
        </a>
      </div>
    </React.Fragment>
  );

  try {
    if (props.isMobile) {
      content = (
        <QueueAnim className="banner-title-wrapper" type="bottom">
          <div key="line" className="title-line-wrapper">
            <div
              className="title-line"
              style={{ transform: 'translateX(-64px)' }}
            />
          </div>
          {content}
        </QueueAnim>
      );
    } else {
      content = (
        <QueueAnim className="banner-title-wrapper" type="right">
          <div key="line" className="title-line-wrapper">
            <div
              className="title-line"
              style={{ transform: 'translateX(-64px)' }}
            />
          </div>
          {content}
        </QueueAnim>
      );
    }
  } catch (error) {
    // Handle the error here, you can log it or show a fallback content
    content = (
      <div>
        Oops! Something went wrong while loading the content.
      </div>
    );
  }

  return (
    <div className="banner-wrapper">
      {props.isMobile && (
        <TweenOne animation={{ opacity: 1 }} className="banner-image-wrapper">
          <div className="home-banner-image">
            <img
              alt="banner"
              src={banner}
              width="100%"
            />
          </div>
        </TweenOne>
      )}
      <QueueAnim className="banner-title-wrapper" type={props.isMobile ? 'bottom' : 'right'}>
        <div key="line" className="title-line-wrapper">
          <div
            className="title-line"
            style={{ transform: 'translateX(-64px)' }}
          />
        </div>
        <h1 key="h1">{t("Home.IListenGPT")}</h1>
        <p key="content">
          {t("Home.Description")}
        </p>
        <div key="button" className="button-wrapper">
            <Link to="/Interview">
              <Button className="bannerBtn" type="primary" >
              {t("Home.Try_Service")}
              </Button>
            </Link>
        </div>
      </QueueAnim>
      {!props.isMobile && (
        <TweenOne animation={{ opacity: 1 }} className="banner-image-wrapper">
          <img src={banner} alt="IListenGPT banner" width="85%" />
        </TweenOne>
      )}
    </div>
  );
}

Banner.propTypes = {
  isMobile: PropTypes.bool.isRequired,
};

export default Banner;
