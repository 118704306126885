import './DataTable.css';
import {main} from "../clients/getalldata";
import React, { useState,useEffect } from 'react';
import { Table, Button } from 'antd';
import * as d3 from 'd3'

function DataTable() {
  const [activeTable, setActiveTable] = useState('table1');
  const [tableColumns, setTableColumns] = useState(new Map());
  const [tableDataSourceMap, setTableDataSourceMap] = useState(new Map());
  const [isDataLoaded, setIsDataLoaded] = useState(false); // 新增状态以跟踪数据是否加载完成

  useEffect(() => {
    const getAllTables = async () => {
      try {
        const results = await main(); // 假设 main() 返回Promise
        buildmap(results); // 假设这个函数会更新tableColumns和tableDataSourceMap
        setIsDataLoaded(true); // 数据加载完成后，更新状态
        console.log(results);
      } catch (error) {
        console.error("Error:", error);
      }
    };
    getAllTables();
  }, []); // 空依赖数组表示此效果仅在组件挂载时运行一次

  function parseDynamoDBItem(item) {
    const parsedItem = {};
    for (const key in item) {
      if (item[key].S !== undefined) {
        parsedItem[key] = item[key].S;
      } else if (item[key].N !== undefined) {
        parsedItem[key] = Number(item[key].N);
      } else if (item[key].BOOL !== undefined) {
        parsedItem[key] = item[key].BOOL ? "false":"true";
      } // 您可以根据需要继续添加更多的类型转换，如B（布尔值）、L（列表）等
    }
    return parsedItem;
  }

  function buildmap(myObject) {
    const newTableColumns = new Map();
    const newTableDataSourceMap = new Map();

    for (let key in myObject) {
      if (myObject.hasOwnProperty(key)) {
        const properties = myObject[key].length > 0 ? Object.keys(myObject[key][0]) : [];
        const columns = properties.map(property => ({
          title: property,
          dataIndex: property,
          key: property,
        }));
        newTableColumns.set(key, columns);
        
        const transformedData = myObject[key].map(item => parseDynamoDBItem(item));

        // 为每个数据项添加基于索引的 key 属性
        const dataSourceWithKeys = transformedData.map((item, index) => ({
          key: index.toString(),
          ...item
          // 将索引转换为字符串作为 key
        }));


      newTableDataSourceMap.set(key, dataSourceWithKeys);
      }
    }

    setTableColumns(newTableColumns);
    setTableDataSourceMap(newTableDataSourceMap);
  }

  const switchTable = (tableName) => {
    setActiveTable(tableName);
    console.log(tableDataSourceMap.get(tableName));
  };

  return (
    <div>
      {isDataLoaded && (
        <div>
          {Array.from(tableColumns.keys()).map(tableName => (
            <Button key={tableName} onClick={() => switchTable(tableName)}>
              显示表格 {tableName}
            </Button>
          ))}
          
          {activeTable && tableDataSourceMap.has(activeTable) && (
            <>
            {/* {tableDataSourceMap.length} */}
            {/* {Object.entries(tableDataSourceMap).map(([key,value]) => {
              <div key={key}>
                
                {key}
                {value}
              </div>
            })} */}
            
            <Table dataSource={tableDataSourceMap.get(activeTable)} columns={tableColumns.get(activeTable)} />
            </>
          )}
        </div>
      )}
      {!isDataLoaded && <div>正在加载数据...</div>}
    </div>
  );
}

export default DataTable;
