import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Card } from "antd";
import "./index.css";

import Header from "../../component/Header";
import UserInfo from "./components/UserInfo";
import UserPrompt from "./components/UserPrompt";
import {t, useTranslation} from 'react-i18next';

export default function UserProfile() {
  const [fullName, setFullName] = useState("");
  const [activeTabKey, setActiveTabKey] = useState("info");
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (user) {
      const userObj = JSON.parse(user);
      setFullName(userObj.full_name?.S);
    }
  }, []);

  const tabList = [
    {
      key: "info",
      tab: t("profile.Info")
    },
    {
      key: "prompts",
      tab: t("profile.Prompts")
    }
  ];

  const contentList = useMemo(() => {
    return {
      info: <UserInfo />,
      prompts: <UserPrompt />,
    };
  }, []);

  const onTabChange = useCallback((key) => {
    setActiveTabKey(key);
  }, []);

  return (
    <>
      <Header name={fullName} LoggedIn={true} />
      <div className="profile-wrapper">
        <Card
          title={t("profile.Profile")}
          className="profile-card"
          headStyle={styleSheet.cardHeader}
          tabList={tabList}
          activeTabKey={activeTabKey}
          onTabChange={onTabChange}
        >
          {contentList[activeTabKey]}
        </Card>
      </div>
    </>
  );
}

const styleSheet = {
  cardHeader: {
    fontSize: "2.2rem",
    textAlign: "center",
  },
  firstNameItem: {
    flexGrow: 1,
    marginRight: "1rem",
  },
  lastNameItem: {
    flexGrow: 1,
  },
  button: {
    width: "30%",
    marginLeft: "1rem",
    marginRight: "1rem",
  },
};
