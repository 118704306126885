import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  boxesIntersect,
  SelectionBox,
  useSelectionContainer
} from "react-drag-to-select";
import "./DragSelect.css";
import { Button, ConfigProvider } from 'antd';


  const MouseSelection = React.memo(
  ({ eventsElement, onSelectionChange, onSelectionEnd }) => {
    const selection = useRef({});
    const dragType = useRef("NORMAL");
    const keysDown = useRef([]);

    useEffect(() => {
      const keyDownListener = (e) => {
        if (e.ctrlKey || e.metaKey) {
          if (!keysDown.current.includes("CTRL")) {
            keysDown.current.push("CTRL");
          }
        }
      };
      const keyUpListener = (e) => {
        if (e.key === "Meta" || e.key === "Control") {
          keysDown.current = keysDown.current.filter((key) => key !== "CTRL");
        }
      };

      document.addEventListener("keydown", keyDownListener);
      document.addEventListener("keyup", keyUpListener);

      return () => {
        document.removeEventListener("keydown", keyDownListener);
        document.removeEventListener("keyup", keyUpListener);
      };
    }, []);

    const { DragSelection } = useSelectionContainer({
      onSelectionChange: (box) => {
        selection.current = box;
        onSelectionChange(box, dragType.current);
      },
      onSelectionStart: () => {
        if (keysDown.current.includes("CTRL")) {
          dragType.current = "CTRL";
        } else {
          dragType.current = "NORMAL";
        }
      },
      onSelectionEnd: () => {
        if (selection.current?.height >= 5) {
          onSelectionEnd(selection.current, dragType.current);
          selection.current = {};
        }
      },
      eventsElement
    });

    return <DragSelection />;
  }
);

const DragSelect = (props) => {
  const elementsContainer = useRef();
  const focusedItem = useRef();
  const prevFocusedItem = useRef();

  const handleSelectionChange = useCallback((box) => {
    // setSelectionBox(box);
  }, []);

  const handleSelectionEnd = useCallback(
    (box, dragType) => {
      let indexesToSelect = dragType === "CTRL" ? props.selectedIndexes.slice() : [];
      const elementsContainer = document.getElementById("elements-container");
      if (elementsContainer) {
        let firstItem = true;
        let toggleOn = true;
        Array.from(elementsContainer.childNodes).forEach((item, index) => {
          const boundingBox = (item).getBoundingClientRect();
          if (boxesIntersect(box, boundingBox)) {
            if (dragType === "CTRL") {
              if (firstItem) {
                firstItem = false;
                toggleOn = !indexesToSelect.includes(index);
              }
              if (toggleOn) {
                indexesToSelect.push(index);
              } else {
                indexesToSelect = indexesToSelect.filter(
                  (idx) => idx !== index
                );
              }
            } else {
              indexesToSelect.push(index);
            }
          }
        });
      }
      props.setSelectedIndexes(indexesToSelect);
      props.setSelectedSentences(indexesToSelect.map((index) => props.sentenceArray[index]));
    },
    [props.sentenceArray, props.selectedIndexes]
  );

  const toggleSelected = (index) => {
    if (props.selectedIndexes.includes(index)) {
      props.setSelectedIndexes(props.selectedIndexes.filter((idx) => idx !== index));
      // Might should sort selected sentences by index in future?
      props.setSelectedSentences(props.selectedIndexes.filter((idx) => idx !== index).map(idx => props.sentenceArray[idx]))
      return;
    }
    props.setSelectedIndexes([...props.selectedIndexes, index]);
    props.setSelectedSentences([...props.selectedSentences, props.sentenceArray[index]]);
  };

  return props.sentenceArray.length > 0 ? (
    <div className="container">
      <div className="componentsGrid">
        <div>
          <div
            ref={elementsContainer}
            style={{
              padding: "20px",
              position: "relative"
            }}
          >
            <div id="elements-container" className="elements-container">
              {props.sentenceArray.map((sentence, i) => ( 
                  <div
                    tabIndex={0}
                    onClick={(e) => {
                      toggleSelected(i);
                    }}
                    onFocus={() => {
                      focusedItem.current = i;
                    }}
                    onBlur={() => {
                      if (focusedItem.current === i) {
                        prevFocusedItem.current = i;
                        focusedItem.current = undefined;
                      }
                    }}
                    key={i}
                    className={`element-drag ${
                      props.selectedIndexes.includes(i) ? "selected" : ""
                    } `}
                  >
                    <div>{props.isSupportedLanguage ? props.sentence_with_keyword(sentence) : sentence}</div>
                    <div style={{marginLeft:"0%", fontSize:"small", color:"gray"}}>{sentence.timeStamp}</div>
                  </div>
                ))}
            
            </div>
            <MouseSelection
              onSelectionChange={handleSelectionChange}
              onSelectionEnd={handleSelectionEnd}
              eventsElement={elementsContainer.current}
            />
          </div>
          <div className="multiControls">
            <ConfigProvider
              theme={{
                token: {
                  /* here is your global tokens */
                },
              }}
            >
              <Button type="primary" 
                size='middle' 
                className="select-all-button"
                onClick={() => {
                  props.setSelectedIndexes(Array.from(props.sentenceArray.keys()));
                  // props.setSelectedSentences(props.sentenceArray);
                }}>
                Select All
              </Button>
              <Button type="primary" 
                size='middle' 
                className="clear-all-button"
                onClick={() => {
                  props.setSelectedIndexes([]);
                  // props.setSelectedSentences([]);
                }}>
                Clear All
              </Button>
            </ConfigProvider>
          </div>
        </div>
        <div>
          <ul className="notesList">
            <li>Try click & drag</li>
            <li>Hold ctrl/command can help keeping multiple drag & selection </li>
            <li>Instead send entire input box, the send button will only send selected sentences </li>
          </ul>
        </div>
      </div>
    </div>
  ) : null;;
};

export default DragSelect;
