import './Footer-elements.css';
import React, { useState, useEffect} from 'react';
import Header from '../component/Header';
import Footer from '../component/Footer';
import { useLocation } from 'react-router-dom';

//ILG 6 Contact Page from Zhan Zhang
function Contact(){
    const location = useLocation();
    const [isLoggedIn,setisLoggedIn] = useState(false);
    const [name, setName] = useState('');

    useEffect(() =>{
        const userstring = sessionStorage.getItem('user');
        const user = JSON.parse(userstring);
        if(user != null){
            setName(user.full_name.S)
        }
    },[]);

    return (
        <div>
            <Header name = {name} LoggedIn = {isLoggedIn} />
            <section className="elements">
                <div className="element">
                    <h2>Contact</h2> 
                    <p>If you have any questions or comments about this notice, how we collect and use your information, your choices or rights regarding such use, or wish to exercise your rights, please do not hesitate to contact us at:</p>
                    <p> Linkedin: <a href="https://www.linkedin.com/company/jreamscape-llc/about/">Jreamscape LLC</a>
                        <br/>
                        Email: <a href="mailto:ilistengpt@gmail.com">ilistengpt@gmail.com</a>
                        <br/>
                        <br/>
                        Jreamscape LLC © 2023
                    </p>
                </div>   
            </section>
            <Footer />
        </div>
      );
}

export default Contact;