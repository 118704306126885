import { retrieveMessageCounter, createMessageCounter, updateMessageCounter } from '../clients/messageCounterMapper';

/**
 * This function will try to retrieve the message counter from the database, if the userId is not existing, it will create and return the message counter,
 * if it exists, then update it.
 */

export async function getCounter() {
  //ILG107 set login state inlocalstorage instead of sessionstorage --Zhan Zhang
  const userId = JSON.parse(localStorage.getItem('user')).user_id.S;
  const messageCounter = await retrieveMessageCounter(userId);
  var remain = 0;
  if (messageCounter.Count === 0) { // The userId is not exist in the database, create the record and return 20.
    createMessageCounter(userId);
    remain = 20;
  } else { // The userId is exist in the database, update it.

    // if the userId is exist in the database, check if the last message date is today, if not, this user should have 20 message remaining.
    let today = new Date().toISOString().slice(0, 10);
    if (messageCounter.Items[0].lastMessageDate.S !== today) {
      updateMessageCounter(userId, 20, today);
      remain = 20;
    } else {
      remain = messageCounter.Items[0].messageRemained.N;
    }
  }
  return remain;
}

/**
 * This function is to update the message counter in the database.
 * @param {} messageRemain 
 */
export async function updateCounter(messageRemain) {
  let today = new Date().toISOString().slice(0, 10);
  //ILG107 set login state inlocalstorage instead of sessionstorage --Zhan Zhang
  const userId = JSON.parse(localStorage.getItem('user')).user_id.S;
  await updateMessageCounter(userId, messageRemain - 1, today);
}