import './Footer-elements.css';
import React, { useState, useEffect} from 'react';
import Header from '../component/Header';
import Footer from '../component/Footer';
import { useLocation } from 'react-router-dom';

//ILG 7 About Page from Zhan Zhang
function About(){
    const location = useLocation();
    const [isLoggedIn,setisLoggedIn] = useState(false);
    const [name, setName] = useState('');

    useEffect(() =>{
        const userstring = sessionStorage.getItem('user');
        const user = JSON.parse(userstring);
        if(user != null){
        setName(user.full_name.S)
        }
    },[]);

    return (
        <div>
            <Header name = {name} LoggedIn = {isLoggedIn} />
            <section className="elements">
                <div className="element">
                    <h2>About Jreamscape</h2>
                    <h3>Vision:</h3>
                    <p>Our vision is to revolutionize the way people interact with technology by unlocking the power of advanced AI. We strive to empower conversations and transform technology to create meaningful and personalized experiences for users worldwide.</p>      
                    <h3>What we do:</h3>
                    <p>Specializing in Conversational AI, Advanced Speech Recognition, AI-Powered Automation, and Data Analytics. Our technological solutions are designed to enable real-time chat interactions for scenarios ranging from interviews to meetings. Passionate about technology, we deliver cutting-edge solutions that empower seamless communication and enhance collaboration experiences.</p>       
                    <h3>Values:</h3>
                    <p>Innovation<br/>Excellence<br/>Collaboration<br/>Customer Focus<br/></p>
                    <h3>Mission:</h3>
                    <p>Together, we are shaping the future of technology by unlocking the potential of advanced AI, empowering conversations, and delivering transformative solutions that revolutionize the way we live, work, and connect.</p>
                </div>   
            </section>
            <Footer />
        </div>
      );
}

export default About;