import AWS from "aws-sdk";

export const dynamodb = new AWS.DynamoDB({
  region: "us-east-2", // Replace with the region you want to use
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
});

export function addItemToDynamoDB(item) {
  const params = {
    TableName: "chat_history",
    Item: {
      id: { S: Math.random().toString(36).substring(7) },
      text: { S: item.text },
      user_id: { S: item.userId || "" },
      created_at: { N: item.timestamp },
      meeting_id: { S: item.meetingId }
    },
  };

  dynamodb.putItem(params, function (err, data) {
    if (err) console.log(err);
    else console.log(data);
  });
}

export function retrieveFromDynamoDB(meetingId) {
  const params = {
    TableName: "chat_history",
    ExpressionAttributeValues: {
      ":meeting_id": { S: meetingId },
    },
    FilterExpression: "meeting_id = :meeting_id",
  };

  return new Promise((resolve, reject) => {
    dynamodb.scan(params, function (err, data) {
      if (err) {
        reject(err);
      } else {
        resolve(data);
      }
    });
  });
}

/**
 * This function retrieves all the meetings from the user.
 * @param {*} userId
 * @returns complete meeting details of an user.
 */

export function retrieveMeetingsFromDynamoDB(userId) {
  const params = {
    TableName: "chat_history",
    ExpressionAttributeValues: {
      ":user_id": { S: userId },
    },
    FilterExpression: "user_id = :user_id",
  };

  return new Promise((resolve, reject) => {
    dynamodb.scan(params, function (err, data) {
      if (err) {
        reject(err);
      } else {
        resolve(data);
      }
    });
  });
}

/**
 * This function retrieves all the meeting data
 * @returns complete meeting details
 */

export function retrieveAllMeetingsDataFromDynamoDB() {
  const params = {
    TableName: "chat_history",
  };

  return new Promise((resolve, reject) => {
    dynamodb.scan(params, function (err, data) {
      if (err) {
        reject(err);
      } else {
        resolve(data);
      }
    });
  });
}