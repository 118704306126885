import React, { useState, useMemo } from "react";
import "./Header.css";
import { Link } from "react-router-dom";
import Logo from "../pic/apple-touch-icon.png";
import Translate from "../pic/translate.png";
import { Menu, Dropdown } from "antd";
import { withTranslation } from "react-i18next";
import { useTranslation, Trans } from 'react-i18next';
import { Modal, Button, Select, Divider } from 'antd';

import { 
  SketchOutlined,
  LogoutOutlined,
  ContactsOutlined,
} from '@ant-design/icons';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDropdownOpen: false,
      isHoveringPopup: false,
      logoutOpen: false,
    };
  }
  handleLogout = () => {
    this.setState((prevState) => ({
      logoutOpen: !prevState.logoutOpen,
    }));
  };
  logOut = () => {
    //ILG107 set login state inlocalstorage instead of sessionstorage --Zhan Zhang
    localStorage.clear();
    sessionStorage.clear();
  };

  scrollToHomeThenFeatures = () => {
    if (window.location.pathname !== "/") {
      // If the current page is not '/', navigate to '/feature'
      window.location.assign("/features");
    } else {
      // If the current page is '/', perform page internal scroll
      const element = document.getElementById("page1-wrapper");
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  };
  handleMainLanguage = (value) => {
    this.props.i18n.changeLanguage(value);
  };
  render() {
    const {name, LoggedIn, userId} = this.props;
    const items = [
      { label: (
        <a href="#" onClick={()=>this.props.i18n.changeLanguage('en')}>
          English
        </a>
      ) },
      { label: (
        <a href="#" onClick={()=>this.props.i18n.changeLanguage('cn')}>
          Chinese
        </a>
      )  },
    ];
    const logOut = (
      <Menu>
        <Menu.Item>
          <a href="/profile"><ContactsOutlined />  {this.props.t("Home.Profile")}</a>
        </Menu.Item>
        <Menu.Item>
          <a href="/premium"><SketchOutlined />  {this.props.t("Premium")}</a>
        </Menu.Item>
        <Menu.Item>
          <a href="/" onClick={this.logOut}>
          <LogoutOutlined />  {this.props.t("Home.SignOut")}
          </a>
        </Menu.Item>
      </Menu>
    );
    return (
      <header>
        <div id="logo" to="/">
          <Link to="/">
            <img src={Logo} alt="IListenGPT logo" />
            <span>{this.props.t("Home.IListenGPT")}</span>
          </Link>
        </div>
        <nav>
          <ul>
            <li>
              <a href="/">{this.props.t("Home.Home")}</a>
            </li>
            <li>
              <a onClick={this.scrollToHomeThenFeatures}>
                {this.props.t("Home.Feature")}
              </a>
            </li>
            <li>
              <Link to="/contact">{this.props.t("Home.Contact")}</Link>
            </li>
            <li>
              <Link to="/interview">{this.props.t("Home.Interview")}</Link>
            </li>
          </ul>
          <ul>
            <li>
            <Dropdown menu={{ items }}>
              <a href="#">
                <img src={Translate}></img>
              </a>
            </Dropdown>
            </li>
            {name == "" ? (
              <>
                <li>
                  <a href="/login">{this.props.t("Home.Login")}</a>
                </li>
                <li>
                  <a href="/signup">{this.props.t("Home.Signup")}</a>
                </li>
              </>
            ) : (
              <>
                <li>
                  <Dropdown overlay={logOut}>
                    <a className="logout" href="#">
                      {name}
                    </a>
                  </Dropdown>
                </li>
              </>
            )}
          </ul>
        </nav>
      </header>
    );
  }
}

export default withTranslation()(Header);