import { dynamodb } from "./dynamoClient.js";

/**
 * insert user info into DynamoDB
 */

export function addUserToDynamoDB(user) {
  const params = {
    TableName: "user",
    Item: {
      //TODO: the user_id generation might be different in the future.
      user_id: { S: Math.random().toString(36).substring(7) },
      full_name: { S: user.name },
      first_name: { S: user.firstName },
      last_name: { S: user.lastName },
      date_of_birth: { S: user.dateOfBirth },
      email: { S: user.email },
      created_at: { N: user.timestamp },
      password: { S: user.password },
      premium_user: { BOOL: false },
    },
  };

  dynamodb.putItem(params, function (err, data) {
    if (err) console.log(err);
    else console.log(data);
  });
}

/**
 * retrieve user info into DynamoDB
 */

export function retrieveUserFromDynamoDB(email) {
  const params = {
    TableName: "user",
    FilterExpression: "email = :email",
    ExpressionAttributeValues: {
      ":email": { S: email },
    },
  };

  return new Promise((resolve, reject) => {
    dynamodb.scan(params, function (err, data) {
      if (err) {
        reject(err);
      } else {
        resolve(data);
      }
    });
  });
}

export function updateUserFromDynamoDB(userId, newVals) {
  const params = {
    TableName: "user",
    Key: {
      user_id: { S: userId },
    },
    UpdateExpression:
      "SET first_name = :firstName, last_name = :lastName, full_name = :full_name, date_of_birth = :dob",
    ExpressionAttributeValues: {
      ":firstName": { S: newVals.firstName },
      ":lastName": { S: newVals.lastName },
      ":full_name": { S: newVals.firstName + " " + newVals.lastName },
      ":dob": { S: newVals.dob },
    },
  };

  return new Promise((resolve, reject) => {
    dynamodb.updateItem(params, (err, data) => {
      if (err) {
        reject(err);
      } else {
        resolve(data);
      }
    });
  });
}

export function updateUserPwdFromDynamoDB(userId, newPwd) {
  const params = {
    TableName: "user",
    Key: {
      user_id: { S: userId },
    },
    UpdateExpression: "SET password = :pwd",
    ExpressionAttributeValues: {
      ":pwd": { S: newPwd },
    },
  };

  return new Promise((resolve, reject) => {
    dynamodb.updateItem(params, (err, data) => {
      if (err) {
        reject(err);
      } else {
        resolve(data);
      }
    });
  });
}

export function updateUserPromptsToDynamoDB(userId, prompts) {
  const params = {
    TableName: "user",
    Key: {
      user_id: { S: userId },
    },
    UpdateExpression: "SET prompts = :prompts",
    ExpressionAttributeValues: {
      ":prompts": {
        L: prompts.map((item) => {
          return { S: item };
        }),
      },
    },
  };

  return new Promise((resolve, reject) => {
    dynamodb.updateItem(params, (err, data) => {
      if (err) {
        reject(err);
      } else {
        resolve(data);
      }
    });
  });
}


export function updatepremium(userId, successCallback, errorCallback){
  const params = {
    TableName: "user",
    Key: {
      user_id: { S: userId },
    },
    UpdateExpression: "SET premium_user = :premium",
    ExpressionAttributeValues: {
      ":premium": { BOOL: true },
    },
    
  };
  dynamodb.updateItem(params, (updateErr, updateData) => {
    if (updateErr) {
      console.error("Error updating user's premium status: ", updateErr);
      if(errorCallback){
        errorCallback();
      }
    } else {
      console.log("User is already a premium user.");
      if(successCallback){
        successCallback();
      }
    }
  });
}
