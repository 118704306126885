import React, { useState, useCallback, useEffect } from "react";
import {
  MailOutlined,
  CalendarOutlined,
  KeyOutlined,
  LockOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Input, Form, Button } from "antd";
import {
  changeUserInfo,
  changeUserPassword,
} from "../../../service/userAction";

import "./UserInfo.css";
import "../index.css";
import { useTranslation, Trans } from 'react-i18next';

export default function UserInfo() {
  const [userInfoForm] = Form.useForm();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [dob, setDOB] = useState("");
  const { t, i18n } = useTranslation();

  const updateUI = useCallback(() => {
    const user = localStorage.getItem("user");
    if (user) {
      const userObj = JSON.parse(user);
      console.log(userObj);
      setFirstName(userObj.first_name?.S);
      setLastName(userObj.last_name?.S);
      setEmail(userObj.email?.S);
      setDOB(userObj.date_of_birth?.S);
    }
  }, []);

  useEffect(() => {
    updateUI();
  }, [updateUI]);

  const validate = useCallback((newVals) => {
    if (
      !/^[a-z ,.'-]+$/i.test(newVals.firstName) ||
      !/^[a-z ,.'-]+$/i.test(newVals.lastName)
    ) {
      alert("Invalid name!");
      return false;
    } else if (
      !/^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/i.test(
        newVals.dob
      )
    ) {
      alert("Invalid date of birth!");
      return false;
    }
    return true;
  }, []);

  const handleChangeUserInfo = useCallback(
    (values) => {
      if (!window.confirm(t("profile.Confirm"))) return;

      const newVals = {
        firstName: values.firstName || firstName,
        lastName: values.lastName || lastName,
        dob: values.dob || dob,
      };

      if (!validate(newVals)) return;

      changeUserInfo(email, newVals)
        .then((user) => {
          if (user !== null) {
            alert("User info changed successfully!");
            localStorage.setItem("user", JSON.stringify(user));
            updateUI();
            window.location.reload();
          } else {
            alert("User info change failed!");
          }
        })
        .finally(() => {
          userInfoForm.resetFields();
        });
    },
    [dob, email, firstName, lastName, updateUI, userInfoForm, validate]
  );

  const handleResetUserInfo = useCallback(() => {
    if (window.confirm(t("profile.ResetConfirm"))) {
      userInfoForm.resetFields();
    }
  }, [userInfoForm]);

  const handleChangePwd = useCallback(
    (values) => {
      const { oldPwd, newPwd } = values;
      changeUserPassword(email, oldPwd, newPwd).then((flag) => {
        if (flag) {
          alert("Password changed successfully!");
          window.location.href = "/";
        } else {
          alert("Password change failed!");
        }
      });
    },
    [email]
  );

  const renderMailIcon = useCallback((item) => {
    const icons = {
      name: <UserOutlined className="input-icon" />,
      email: <MailOutlined className="input-icon" />,
      dob: <CalendarOutlined className="input-icon" />,
      key: <KeyOutlined className="input-icon" />,
      lock: <LockOutlined className="input-icon" />,
    };
    return icons[item];
  }, []);

  return (
    <>
      <Form
        form={userInfoForm}
        className="user-info"
        onFinish={handleChangeUserInfo}
      >
        <div className="name-section">
          <Form.Item name="firstName" style={styleSheet.firstNameItem}>
            {firstName && (
              <Input
                className="input-element"
                defaultValue={firstName}
                placeholder="First Name"
                prefix={renderMailIcon("name")}
              />
            )}
          </Form.Item>
          <Form.Item name="lastName" style={styleSheet.lastNameItem}>
            {lastName && (
              <Input
                className="input-element"
                defaultValue={lastName}
                placeholder="Last Name"
                prefix={renderMailIcon("name")}
              />
            )}
          </Form.Item>
        </div>
        <Form.Item>
          {email && (
            <Input
              className="input-element"
              defaultValue={email}
              prefix={renderMailIcon("email")}
              disabled
            />
          )}
        </Form.Item>
        <Form.Item name="dob">
          {dob && (
            <Input
              className="input-element"
              defaultValue={dob}
              placeholder="Date of Birth"
              prefix={renderMailIcon("dob")}
            />
          )}
        </Form.Item>
        <Form.Item style={{ textAlign: "center" }}>
          <Button type="primary" htmlType="submit" style={styleSheet.button}>
            {t('profile.Save')}
          </Button>
          <Button
            htmlType="button"
            onClick={handleResetUserInfo}
            style={styleSheet.button}
            className="default-button"
          >
            {t('profile.Reset')}
          </Button>
        </Form.Item>
      </Form>
      <Form className="pwd-section" onFinish={handleChangePwd}>
        <p className="pwd-section-title">{t('profile.changePassword')}</p>
        <Form.Item
          name="oldPwd"
          rules={[
            { required: true, message: "Please input your old password" },
          ]}
        >
          <Input.Password
            className="input-element"
            placeholder={t("profile.oldPassword")}
            prefix={renderMailIcon("key")}
          />
        </Form.Item>
        <Form.Item
          name="newPwd"
          rules={[
            { required: true, message: "Please input your new password!" },
          ]}
        >
          <Input.Password
            className="input-element"
            placeholder={t("profile.newPassword")}
            prefix={renderMailIcon("lock")}
          />
        </Form.Item>
        <Form.Item style={{ textAlign: "center" }}>
          <Button type="primary" htmlType="submit" style={styleSheet.button}>
            {t('profile.submit')}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}

const styleSheet = {
  firstNameItem: {
    flexGrow: 1,
    marginRight: "1rem",
  },
  lastNameItem: {
    flexGrow: 1,
  },
  button: {
    width: "30%",
    marginLeft: "1rem",
    marginRight: "1rem",
  },
};
