import React, { useState, useContext, useRef } from 'react';
import './InterviewPages.css';
import SideBar from '../component/Interview/Sidebar';
import { Layout, Menu, theme, Button, ConfigProvider } from 'antd';
import dataContext from '../dataContext';
import ChatWindow from '../component/Interview/ChatWindow';
import ChatComponent from '../component/Interview/Chatbar';
import IconButtons from '../component/Interview/IconButtons';
import SelectWindow from '../component/Interview/SelectWindow';

const { Header, Content, Footer, Sider } = Layout;

/** 
 * ------------------------------------------------------
 * |                   Data context                     |
 * ------------------------------------------------------
*/

export const InterviewContextProvider = (({ children }) => {
  const [text, setText] = useState('');
  const [responses, setResponses] = useState([]); //responses should be a list contains both quesion and answer.
  const [selectedSentences, setSelectedSentences] = useState([]); //this variable is used to store the sentences that has been checked.
  const [isStored, setIsStored] = useState(false);
  const [language, setLanguage] = useState("en-US"); //ZQ: Add language drop-down menu 
  const [gptVersion, setGPTVersion] = useState("gpt-3.5-turbo");
  const userKey = useRef(null);
  const soundDetector = useRef(false);
  const [recognizer, setRecognizer] = useState(null);
  const [isRunning, setIsRunning] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const [selectBarCollapsed, setSelectBarCollapsed] = useState(true);
  const [historyBarCollapsed, setHistoryBarCollapsed] = useState(false);
  const messageRemaining = useRef(0);
  const [promptRequest, setPromptRequest] = useState(null);

  return (
    <dataContext.Provider value={
      { text,
        setText,
        responses,
        setResponses,
        selectedSentences,
        setSelectedSentences,
        isStored,
        setIsStored,
        language,
        setLanguage,
        gptVersion,
        setGPTVersion,
        userKey,
        soundDetector,
        isRunning,
        setIsRunning,
        recognizer,
        setRecognizer,
        seconds, 
        setSeconds,
        selectBarCollapsed,
        setSelectBarCollapsed,
        historyBarCollapsed,
        setHistoryBarCollapsed,
        messageRemaining,
        promptRequest,
        setPromptRequest
      }}>
      {children}
    </dataContext.Provider>
  )
})

/** 
 * ------------------------------------------------------
 * |                   Page Display                     |
 * ------------------------------------------------------
*/
  
function PageLayout() {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  
  return (
    <InterviewContextProvider>
      <Layout className='full-screen-layout'>
        <SideBar />
        <Layout>
          <Content>
            <div className='content-color'>
              <ChatWindow />
              <ChatComponent />
            </div>
            <div className='top-right-component'>
              <IconButtons />
            </div>
          </Content>
        </Layout>
        <SelectWindow />
      </Layout>
    </InterviewContextProvider>
  );
};
  
function InterviewPages() {
  return (
    <PageLayout />
  );
}
  
export default InterviewPages;