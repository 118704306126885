import { endsWith } from "lodash";
import {
  addUserToDynamoDB,
  retrieveUserFromDynamoDB,
  updateUserFromDynamoDB,
  updateUserPwdFromDynamoDB,
  updateUserPromptsToDynamoDB,
} from "../clients/user";
import {
  retrieveFromPromptTable,
  addItemToPromptTable,
  deleteFromPromptTable,
  updateItemFromPromptTable,
} from "../clients/prompt";
import sha1 from "sha1"; // sha1 is used to hash user password

/**
 * Core function to validate user when logging in, and keep user logged after verified.
 */

//TODO assume here have received 'loggin' from client
export async function logInUser(email, password) {
  //convert email to lower case in case we missed their email
  email = email.toString().toLowerCase();

  //try to retrieve user from DynamoDB
  const data = await retrieveUserFromDynamoDB(email);

  if (data != null && data.Items.length > 0) {
    //user is found in DynamoDB
    var user = data.Items[0];
    var hash = sha1(password);
    console.log(user);
    if (user.password.S === hash) {
      //TODO user need to maintain loged in status.
      console.log("log in success");

      return user;
    }
  }
  return null;
}

/**
 * Core function for user sign up.
 */

//TODO assume here have received 'sign up' from client, the input might be a object of user.
export async function signUpUser(
  email,
  password,
  last_name,
  first_name,
  date_of_birth
) {
  //convert email to lower case in case we missed their email
  email = email.toString().toLowerCase();

  //try to retrieve user from DynamoDB
  const data = await retrieveUserFromDynamoDB(email);
  if (data.Items.length > 0) {
    //user is found in DynamoDB, no need to sign up again.
    alert("This email has been signed up");
    return false;
  } else {
    var user = {};
    user.email = email;
    user.password = sha1(password);
    user.dateOfBirth = date_of_birth;
    user.lastName = last_name;
    user.firstName = first_name;
    user.name = first_name + " " + last_name;
    user.timestamp = Date.now().toString();

    //user is not found in DynamoDB, need to sign up.
    addUserToDynamoDB(user);
    console.log("sign up success");
    return true;
  }
}

export async function getUser(email) {
  email = email.toString().toLowerCase();
  const data = await retrieveUserFromDynamoDB(email);
  if (data != null && data.Items.length > 0) {
    //user is found in DynamoDB
    var user = data.Items[0];
    return user;
  }
  return null;
}

export async function changeUserInfo(email, newVals) {
  const user = await getUser(email);
  if (user !== null) {
    await updateUserFromDynamoDB(user.user_id?.S, newVals);
    const newUser = await getUser(email);
    return newUser;
  }
  return null;
}

export async function changeUserPassword(email, oldPwd, newPwd) {
  const user = await getUser(email);
  if (user !== null && user.password?.S === sha1(oldPwd)) {
    await updateUserPwdFromDynamoDB(user.user_id?.S, sha1(newPwd));
    return true;
  }
  return false;
}

export async function getUserPrompts(userId) {
  if (!userId) return null;
  try {
    const prompts = await retrieveFromPromptTable(userId);
    return prompts;
  } catch (error) {
    console.error("Failed to retrieve user prompts");
    throw Error(error);
  }
}

export async function updateUserPrompts(email, prompt, type) {
  if (!["add", "delete", "edit"].includes(type))
    throw Error("Illigal operation: invalid user prompts updates ");

  const user = await getUser(email);
  if (user === null) throw Error("User not found");

  try {
    if (type === "add") {
      await addItemToPromptTable({
        userId: user.user_id?.S,
        category: "personal",
        name: prompt.name,
        prompt: prompt.text,
      });
    } else if (type === "delete") {
      await deleteFromPromptTable(prompt.id, user.user_id?.S);
    } else if (type === "edit") {
      await updateItemFromPromptTable(user.user_id?.S, prompt);
    }
  } catch (error) {
    console.error("Failed to update user prompts");
    throw Error(error);
  }

  try {
    const newPrompts = await getUserPrompts(user.user_id?.S);
    return newPrompts;
  } catch (error) {
    throw Error(error);
  }
}
