import React,{createContext, useState, useContext, useEffect} from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import {Navigate} from 'react-router-dom'
import SpeechToText, { ContextProvider } from './SpeechToText';
import Homepage from './ui/HomePage';
import './App.css'; // Import the CSS file
import LoginPage from './ui/LoginPage';
import SignupPage from './ui/SignupPage'
import InterviewSidebar from './component/InterviewSidebar'
// import InterviewPage, { InterviewContextProvider } from './ui/InterviewPage'
import InterviewPages, { InterviewContextProvider } from './ui/InterviewPages';
import UserProfile from "./ui/UserProfile";
import About from "./component/About";
import Contact from "./component/Contact";
import PrivacyPolicy from "./component/PrivacyPolicy";
import TermsOfService from "./component/TermsOfService";
import Features from "./component/Home/Features";
import Header from "./component/Header";
import Footer from "./component/Footer";
import Premium from "./ui/Premium";

import PremiumCheckout from "./ui/PremiumCheckout";
import DataTable from './ui/DataTable';
import Createbarchart from './ui/DataVisualization';

const BackgroundWrapper = ({ children }) => {
  const location = useLocation();

  const getBackground = () => {
    if (location.pathname === "/login" || location.pathname === "/signup") {
      return "white"; // Background color for the login page
    } else {
      return "linear-gradient(to bottom, #FF4136, #FF851B, #FFDC00, #2ECC40, #171C28)"; // Background color for other pages
    }
  };

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: getBackground(),
        minHeight: "100vh",
      }}
    >
      {children}
    </div>
  );
};

const InterviewWithSidebar = () => {
  return (
    <div className='interview'>
      {/* <InterviewContextProvider>
        <InterviewSidebar />
        <InterviewPage /> */}
        <InterviewPages />
      {/* </InterviewContextProvider> */}
    </div>
  );
};

const Feature = () => {
  return (
    <div>
      <Header />
      <Features />
      <Footer />
    </div>
  );
};

const App = () => {
  const checkAuth = () => {
    //ILG107 set login state inlocalstorage instead of sessionstorage --Zhan Zhang
    const auth = localStorage.getItem("LoggedIn");
    return auth == "true";
  };

  return (
    <Router>
      <div className="container">
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/about" element={<About />} />
          <Route path="/features" element={<Feature />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/premium" element={<Premium />} />
          <Route path="/precheckout" element={<PremiumCheckout />} />
          <Route path="/showdatatable" element={<DataTable />} />
          <Route path="/showcharts" element={<Createbarchart />} />
          <Route
            path="/login"
            element={
              <BackgroundWrapper>
                <LoginPage />
              </BackgroundWrapper>
            }
          />
          <Route
            path="/signup"
            element={
              <BackgroundWrapper>
                <SignupPage />
              </BackgroundWrapper>
            }
          />
          <Route path="/profile" element={<UserProfile />} />
          {checkAuth() ? (
            <Route path="/Interview" element={<InterviewWithSidebar />} />
          ) : (
            <Route path="/Interview" element={<Navigate to="/login" />} />
          )}
          
        </Routes>
      </div>
    </Router>
  );
};

export default App;
