import React, { useState, useContext } from 'react';
import './Sidebar.css';
import { MessageOutlined, PlusOutlined, ToTopOutlined, DeleteOutlined } from '@ant-design/icons';
import { Layout, Menu, theme, Button, ConfigProvider } from 'antd';
import dataContext from '../../dataContext';
import { retrieveInterviewTextFromDynamoDB, retrieveInterviewFromDynamoDB, hideHistoryFromUser } from '../../clients/interviewMapper';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation, Trans } from 'react-i18next';


const { Header, Content, Footer, Sider } = Layout;

function SideBar() {

  const { setText, setSelectedSentences, setResponses, isStored, userKey, setSeconds, setIsRunning, historyBarCollapsed, setHistoryBarCollapsed } = useContext(dataContext);
  const [interviewMeetingInfo, setInterviewMeetingInfo] = React.useState([]);
  const [currentSelectedHistory, setCurrentSelectedHistory] = React.useState(-1);
  const { t, i18n } = useTranslation();

  /** 
   * ------------------------------------------------------
   * |              Sider head component                  |
   * ------------------------------------------------------
  */

  /** button for new chat */
  function Sider_new_chat() {

      /**
     * This function is called when user clicks on + New Meeting, it regenerate a new meeting ID 
     * and clear text in the textbox.
     */
    function handleAddNewMeeting() {
      // Update meetingID in sessionStorage.
      localStorage.setItem('meetingID', Math.random().toString(36).substring(7));
      setResponses([]);
      setSelectedSentences([]);
      setText('');
      setSeconds(0);
      setIsRunning(false);
      setCurrentSelectedHistory(-1);
    }

    return (
      <span>
        {/* // New meeting button */}
        <Button type="primary" 
                size='large' 
                className='sider_button new_meeting_button'
                onClick={handleAddNewMeeting}>
          {<PlusOutlined />}
          {t('InterviewPage.newChat')}
        </Button>
      </span>
    )
  }

  /** button for collpse controller */
  function Sider_collpse() {
    return (
      <span>
        <Button type="primary" size='large' className='sider_button collapse_button' 
        onClick={ () => setHistoryBarCollapsed(!historyBarCollapsed) }>
          {<ToTopOutlined style={{ transform: 'rotate(-90deg)' }}/>}
        </Button>
      </span>
    )
  }

  /** This function is used to combine two button in specific color */
  function Sider_head() {
    return (
      <ConfigProvider
        theme={{
          token: {
            colorPrimaryHover: '#022949',
          },
        }}
      >
        <div style={{ display: 'flex' }}>
          <span><Sider_new_chat /></span>
          <span><Sider_collpse /></span>
        </div>

      </ConfigProvider>
    )
  }

  /**
   * ------------------------------------------------------
   * |        History retrieve component                  |
   * ------------------------------------------------------
   */

  /**
     * This function is to retrieve all meeting of an user and ordered meetingIds by create time,
     * So the meetingIDs can display in frontend.
     * @param {*} userId 
     * returns meetingIds
     */
  const retrieveInterviewMeetingInfo = async (userId) => {
    try {
      const result = await retrieveInterviewFromDynamoDB(userId)
      // sort all chat histories for the user by create time.
      const sortMeetings = result.Items.sort((a, b) => b.created_at.N - a.created_at.N);
      //ILG107 set login state inlocalstorage instead of sessionstorage --Zhan Zhang
      const deletedMeetingIds = JSON.parse(localStorage.getItem('deletedMeetingIds')) || [];
      const filteredMeetings = sortMeetings.filter((item) => !deletedMeetingIds.includes(item.meeting_id.S));

      let uniqueMeetingId = new Set(); // keep unique meetingIds from array.
      let meeting_datas = []; // save meeting notes and ids in array.

      filteredMeetings.forEach(item => {
        const resArray = JSON.parse(item.text.S);
        const meetingId = item.meeting_id.S;
        const text = resArray.map((res) => res.content).join('; ');
        
        if (!uniqueMeetingId.has(meetingId)) {
          uniqueMeetingId.add(meetingId);
          //A new warning for not adding empty meetings.
          if (text.trim() === ''){
            console.warn('Meeting cannot be empty!');
          }
          else{
            meeting_datas.push([meetingId, text]);
          }
        }
      });
      // update state
      setInterviewMeetingInfo(meeting_datas);
    } catch (error) {
      console.error(error);
    }
  }

  const handleRetrieveMeeting = async (meetingId) => {
    // This method retrive entire interview chat history at once.
    try {
      const result = await retrieveInterviewTextFromDynamoDB(meetingId);
      const texts = JSON.parse(result.Items[0].text.S);
      setResponses(texts);
    } catch (error) {
      console.error(error);
    }
  }

  /**
     * This function is called when user clicks on delete Meeting, it will hide the given meeting 
     * and clear the text in the textbox.
     */
  const handleDeleteMeeting = async (meetingId) => {
    console.log('history deleted');
    try {
      //ILG107 set login state inlocalstorage instead of sessionstorage --Zhan Zhang
      const user = JSON.parse(localStorage.getItem('user'));
      hideHistoryFromUser({ meetingId });
      const deletedMeetingIds = JSON.parse(localStorage.getItem('deletedMeetingIds')) || [];
      deletedMeetingIds.push(meetingId);
      localStorage.setItem('deletedMeetingIds', JSON.stringify(deletedMeetingIds));
      setResponses([]);
      setSelectedSentences([]);
      setText('');
      // Remove the deleted meeting from the interviewMeetingInfo state
      setInterviewMeetingInfo(prevState =>
        prevState.filter(item => item[0] !== meetingId)
      );
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    //ILG107 set login state inlocalstorage instead of sessionstorage --Zhan Zhang
    const user = JSON.parse(localStorage.getItem('user')).user_id.S;
    const request_history = () => {
      retrieveInterviewMeetingInfo(user);
    }
    request_history();
  }, []);

  function DeleteButton({ meetingID }) {
    return (
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#1677ff',
            colorPrimaryHover: '#2a394b',
          },
        }}
      >
          <Button type="primary" icon={<DeleteOutlined />} onClick={() => handleDeleteMeeting(meetingID)} size={'small'} style={{marginLeft: '15px'}}/>
        </ConfigProvider>
    );
  }

  function History_component() {
    return (
      <div className='history-component'>
        <ConfigProvider
          theme={{
            components: {
              Menu: {
                // This part of code is to change the color of the menu.
                darkItemHoverBg: '#022949',
                darkItemColor: '#fff',
              }
            }
          }}
        >
          <Menu theme="dark" mode="inline" selectedKeys={[currentSelectedHistory.toString()]}>
            {interviewMeetingInfo.map((item, index) => {
              const meetingId = item[0];
              //ILG107 set login state inlocalstorage instead of sessionstorage --Zhan Zhang
              const deletedMeetingIds = JSON.parse(localStorage.getItem('deletedMeetingIds')) || [];
              if (deletedMeetingIds.includes(meetingId)) {
                return null; // Skip rendering deleted interview tab
              }
              return (
                <Menu.Item key={index}
                  icon={<MessageOutlined />}
                  onClick={() => handleRetrieveMeeting(meetingId) && setCurrentSelectedHistory(index)}
                  >
                    <span>
                      {currentSelectedHistory === index ? item[1].substring(0,18) + ".." : item[1]}
                      {currentSelectedHistory === index ? <DeleteButton meetingID={meetingId} /> : <></>}
                    </span>
                </Menu.Item>  
              );
            })}
          </Menu>
        </ConfigProvider>
      </div>
    );
  }

  return (
    <Sider
      // The width of the sidebar
      width={256}

      // theme of sidebar
      theme='dark'

      // Collapse controll
      collapsible={true}
      collapsed={historyBarCollapsed} 
      onCollapse={(value) => setHistoryBarCollapsed(value)}
      collapsedWidth="0"
      zeroWidthTriggerStyle={{ top: '0px', bottom: '0px', backgroundColor: '#2a394b' }}
    >
      <Sider_head /> {/* // Buttons that display in the top of sider */}
      <History_component /> {/* // History component */}
    </Sider>
  )

}

export default SideBar;