import React, { useState, useContext, useRef, useEffect } from 'react';
import { Layout, Empty } from 'antd';
import dataContext from '../../dataContext';
import keyword_extractor from "keyword-extractor";
import DragSelect from '../DragSelect';
import { createCompletion, summarize_long_text } from '../../clients/openai';


const { Sider } = Layout;

function SelectWindow(props) {
  const { responses, setResponses, selectedSentences, setSelectedSentences, language, gptVersion, userKey, soundDetector, text, selectBarCollapsed, setSelectBarCollapsed } = useContext(dataContext); // This variable saves both sent and received messages in list.
  const [messageRemaining, setMessageRemaining] = useState(0);
  const [isSupportedLanguage, setIsSupportedLanguage] = useState(false);
  const [error, setError] = useState(null);
  const [selectedIndexes, setSelectedIndexes] = useState([]);


  const ProcessTextArea = () => {
    // List of supported language [。.٠？?؟！!].
    var sentenceArray = text.split(/[。.٠？?؟！!\n]/).map((sentence) => sentence.trim());

    // Only display sentences in text input bar.
    // sentenceArray = [...(selectedSentences.filter(sentence => !sentenceArray.includes(sentence))),
    //                  ...sentenceArray];
    sentenceArray = sentenceArray.filter(sentence => sentence !== "" && sentence !== '\n');
    
    const handleWordClick = async (event) => {
      // try {
      //   const key_word = event.currentTarget.getAttribute('data-value');
      //   const responseText = await createCompletion(gptVersion, 'Use short sentence describe what is ' + key_word, userKey.current.value);
      //   if (responseText) {
      //     if (userKey.current && userKey.current.value === "" && gptVersion === 'gpt-4') setMessageRemaining(messageRemaining - 1); // update counter
      //     setResponses([...responses, ...responseText]);
      //   }

      // } catch (err) {
      //   setError(err.message);
      // }
    };

    const sentence_with_keyword = (sentence) => {
      let keyword = keyword_extractor.extract(sentence,{
                    language:"english",
                    remove_digits: true,
                    return_changed_case:true,
                    remove_duplicates: false
                  });
      let splited_sentence = sentence.split(' ');
      let sentence_list = splited_sentence.map( word => keyword.includes(word.toLowerCase()) ? [word, true] : [word, false] );

      return (
        <span>
          {
            sentence_list.map( (list, index) => {
              if (list[1] === true) {
                return <React.Fragment key={index}>
                        <span onClick={handleWordClick}
                              data-value={list[0]}
                              style={{ color: '#fff' }}
                        >{list[0]} </span>
                       </React.Fragment>
              } else {
                return <React.Fragment key={index}>
                         <span>{list[0]} </span>
                       </React.Fragment>
              }
            })
          }
        </span>
      )
    };

    return (
      <div>
        {sentenceArray.length > 0 ? 
          <DragSelect 
            sentenceArray={sentenceArray} 
            // The selectedSentences might not be used in the future.
            selectedSentences={selectedSentences} 
            setSelectedSentences={setSelectedSentences} 
            selectedIndexes={selectedIndexes}
            setSelectedIndexes={setSelectedIndexes}
            isSupportedLanguage={isSupportedLanguage}
            sentence_with_keyword={sentence_with_keyword}
          /> 
          : 
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={<p style={{ color: '#FDF2E9' }} >Try enter something in textbar...</p>}
            style={{paddingTop: '90%'}} ></Empty>}
      </div>
    );
  };

  return (
    <Sider collapsible 
           collapsed={selectBarCollapsed} 
           onCollapse={(value) => setSelectBarCollapsed(value)} 
           reverseArrow={true}
           collapsedWidth={selectBarCollapsed ? 0 : 300}
           zeroWidthTriggerStyle={{ top: '88vh', bottom: '88vh', backgroundColor: '#2a394b' }} 
           width={300} 
           style={!selectBarCollapsed ? { overflow: 'scroll', maxHeight:'95vh' } : null}>
      <div>
        <ProcessTextArea />
      </div>
    </Sider>
  )
}

export default SelectWindow;