import React from 'react';
import jwt_decode from 'jwt-decode';
import { retrieveUserFromDynamoDB } from '../clients/user';
import { signUpUser } from './userAction';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { toast } from 'react-toastify';

//const client_id = process.env.REACT_APP_GOOGLE_CLIENT_ID;
const client_id = '903242241315-fms4sj2am8vta0tkp2j5r6kq940ot3el.apps.googleusercontent.com';

export default function GoogleOauth ({ onSubmit }) {

  //used to generate a random string for the password.
  function generateRandomString(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charactersLength);
      result += characters.charAt(randomIndex);
    }

    return result;
  }

  function handleCallbackResponse(response) {
    //the response from google is a JSON object, it needs to be decoded.
    const res = jwt_decode(response.credential);
    const user = {
      email: "google-oauth:" + res.email,
      firstName: res.given_name,
      lastName: res.family_name,
      fullName: res.name,
      // Currently we have randomized passwords.
      password: generateRandomString(15),
      // The google oauth did not return birth information, so we set it to 0000-00-00.
      date_of_birth: "0000-00-00",
    };
    // try to retrieve the user from the database.
    retrieveUserFromDynamoDB(user.email)
    .then(retrievedUser => {
      // if the user is not in the database, sign up.
      if (retrievedUser.Items.length === 0) {
        signUpUser(user.email, user.password, user.lastName, user.firstName, user.date_of_birth);
        retrieveUserFromDynamoDB(user.email).then(result => onSubmit(result.Items[0]));
      } else {
        onSubmit(retrievedUser.Items[0]);
      }
    });
  }

  return (
    <div>
      <GoogleOAuthProvider clientId={client_id}>
        <GoogleLogin
          onSuccess={credentialResponse => handleCallbackResponse(credentialResponse) }
          onError={() => {
            console.log('Login Failed');
            toast.warn('Login Failed!', {
              position: toast.POSITION.TOP_CENTER,
              hideProgressBar: true,
              autoClose: 1200,
            });
          }}
          useOneTap
      />
      </GoogleOAuthProvider>
    </div>
  );
}