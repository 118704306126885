import { Configuration, OpenAIApi } from "openai";
import { toast } from 'react-toastify';
import { OpenAI } from "langchain/llms/openai";
import { LLMChain } from "langchain/chains";
import { PromptTemplate } from "langchain/prompts";
import { CharacterTextSplitter } from "langchain/text_splitter";
import { RecursiveCharacterTextSplitter } from "langchain/text_splitter";
import { loadSummarizationChain } from "langchain/chains";
import {SystemMessagePromptTemplate,HumanMessagePromptTemplate,ChatPromptTemplate,} from "langchain/prompts";
import { HumanChatMessage, SystemChatMessage } from "langchain/schema";
import { getCounter, updateCounter } from '../service/messageCounter'
import axios from 'axios';

const maxTokens = 1000;
const temperature = 0.7;


/**
 * This function is used for interact with OPENAI API, currently support chatgpt 3.5 and chatgpt 4.0.
 * Not support text-davinci-003 etc.
 * If text-davinci-003 support is necessary, 
 * 1. use api 'openai.createCompletion' instead openai.createChatCompletion.
 * 2. use prompt instead messages.
 * ----------------------------------------------------------------
 * The format for messages is:
 * [
 *   { role: 'user', content: 'Hello' },
 * ];
 * 
 * The format in prompt is string.
 * @param {*} gptVersion 
 * @param {*} prompt 
 * @returns 
 */

//ILG60 from Zhan Zhang
//This API is used to summaize long text, do not support chat, should only used for summarizition, different with opanai api.
//It's basic idea is to split long text into small chunk, summaize every chunk. Then summaize all the returned summary. Currently, the response is english,
//I left a language convert function, if language is a passed parameter, can call langchain again to translate into target language.

//To use this api in your local environment, should update react-script version and install langchain package,
//in package.json, set react-scripts to “^5.0.1”
//"react-scripts": "^5.0.1",
//than run this in the terminal
//npm install -S langchain
//npm install

//To test this function, uncomment ILG60 example test in ChatButton.js : handle click function.

const summarize_long_text = async (prompt, userKey=null) => {

  //langchain model
  const langchainModel = new OpenAI ({
    openAIApiKey : userKey? userKey: process.env.REACT_APP_OPENAI_API_KEY,
    temperature : 0.9
  });

  const text_splitter = new CharacterTextSplitter({
    chunkSize: 1500,
    chunkOverlap: 300,
  });

  const splitted_long_prompt = await text_splitter.createDocuments([prompt]);

  //build summarize chain
  const chain = loadSummarizationChain(langchainModel,{type:"map_reduce"});
  try{
    const summary = await chain.call({
      
      input_documents: splitted_long_prompt,
    });
    // return message structure:
    // summay: {text:  response}

    

    //commented translated function,{language} should be passed parameter like "chinese"
    
    // const response = await langchainModel.call([
    // new HumanChatMessage(
    //     "Translate this sentence from English to ${language} : ${summary.text}"),
    // ]);
    
    return summary.text;
  }catch(err){
    throw new Error(err.message);
  }
}

  
  
  const createCompletion = async (gptVersion, prompt, userKey=null, prev_responses =[], messageRemaining, country=null) => {
    //refuse access if remain message = 0.
    if (messageRemaining && messageRemaining.current <= 0 && gptVersion === 'gpt-4') return null;

    const configuration = new Configuration({
      apiKey: userKey? userKey: process.env.REACT_APP_OPENAI_API_KEY,
    });
    
    const openai = new OpenAIApi(configuration);
    const model_da_vinci = gptVersion;
    
    const cur_message = [{ role: 'user', content: prompt }];
  
    //get a new array for supporting context, only support the latest 5 conversations, in total 10 obj, use slice for deep copy 
    //From Zhan Zhang ILG87
    let passed_array = [];
    if(prev_responses.length <= 10){
      passed_array = prev_responses.slice(0);
    }else{
      passed_array = prev_responses.slice(prev_responses.length - 10);
    }
    passed_array.push(cur_message[0]);
    
    var ret = null;


    // if (country !== "CN") {
    //   console.log(country + "not in china")
    //   ret = await openai.createChatCompletion({
    //     model: model_da_vinci,
    //     messages: passed_array,
    //     max_tokens: maxTokens,
    //     temperature: temperature,
    //   });
    // } else {
    const url = 'https://agent.ilistengpt.com/v1/chat/completions';
    const data = {
        model: model_da_vinci,
        messages: passed_array,
        max_tokens:maxTokens,
        temperature: temperature
    };

    try {
        const response = await axios.post(url, data, {
            headers: {
                'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
                'Content-Type': 'application/json'
            }
        });

        ret = response;

    } catch (error) {
      throw new Error(`OpenAI API error: ${error.message}`);
    }

    try {  
      let response = null;
      // Only update when use GPT4.
      if (gptVersion === 'gpt-4') {
        //update counter.
        if(messageRemaining && messageRemaining.current > 0) {
          response = ret;
          await updateCounter(messageRemaining.current);
          messageRemaining.current = await getCounter();
        } else {
          // Reach limit, not allow to send message.
          // toast.warn('You have reached GPT4 daily limits, please change to GPT 3.5 or try more in tomorrow.', {
          //   position: toast.POSITION.TOP_CENTER,
          //   hideProgressBar: true,
          //   autoClose: 2000,
          // });

          return null;
        }
      } else {
        response = ret;
      }
     
      cur_message.push(response.data.choices[0].message);

      return cur_message;
    } catch (err) {
      throw new Error(`OpenAI API error: ${err.message}`);
    }
  }
  
  
export {summarize_long_text};
export { createCompletion };
