import './Footer-elements.css';
import React, { useState, useEffect} from 'react';
import Header from './Header';
import Footer from './Footer';
import { useLocation } from 'react-router-dom';

//ILG 5 Privacy Policy Page from Zhan Zhang
function PrivacyPolicy(){
    const location = useLocation();
    const [isLoggedIn,setisLoggedIn] = useState(false);
    const [name, setName] = useState('');

    useEffect(() =>{
        const userstring = sessionStorage.getItem('user');
        const user = JSON.parse(userstring);
        if(user != null){
        setName(user.full_name.S)
        }
    },[]);

    return (
        <div>
            <Header name = {name} LoggedIn = {isLoggedIn} />
            <section className="elements">
                <div className="element">
                    <h2>Privacy Policy</h2>
                    <h3>1.Information We Collect</h3>
                    <p> We may collect the following types of personal data when you visit or interact with our website:
                        <ul>
                            <li>Name: We may request your name to personalize your experience.</li>
                            <li>Email Address: If you choose to subscribe to our newsletter or participate in specific activities, we may ask for your email address.</li>
                            <li>Contact Information: If you reach out to us or interact with our service department, we may collect your contact information to respond to your inquiries and requests.</li>    
                        </ul>
                    </p>
                    <h3>2.Use of Information</h3>
                    <p>We use the collected personal data for the following purposes:
                        <ul>
                            <li>To provide the requested products or services.</li>
                            <li>To send updates and notifications regarding our website or services.</li>
                            <li>To provide personalized recommendations and tailored experiences.</li>
                            <li>To improve the quality and functionality of our website and services.</li>
                            <li>To conduct market research and marketing activities. However, we do not sell or share your personal data with third parties.</li>
                        </ul>
                    </p>
                    <h3>3.Data Storage and Protection</h3>     
                    <p>We implement necessary security measures to protect your personal data from unauthorized access, use, or disclosure. We use secure encryption technology to protect sensitive information during data transmission. We retain your personal data only for as long as necessary and take reasonable measures to ensure data security.</p>
                    <h3>4.Cookies and Tracking Technologies</h3>
                    <p>Our website may use cookies and similar technologies to enhance user experience, analyze website traffic, and deliver targeted advertisements. These technologies may collect anonymous statistical information but do not include personal identification. You can adjust your browser settings to accept or refuse cookies according to your preferences.</p>
                    <h3>5.Third-Party Links</h3>
                    <p>Our website may contain links to third-party websites. Please note that we are not responsible for the privacy practices or data processing activities of these third-party websites. We recommend reading and understanding their respective privacy policies before accessing such websites.</p>
                    <h3>6.Legal Basis</h3>
                    <p>We comply with applicable laws and regulations, including requirements related to data protection and privacy. We take reasonable measures to protect your personal data and adhere to applicable data transfer regulations.</p>
                    <h3>7.Your Privacy Rights</h3>
                    <p>Under applicable law, you have specific rights regarding your personal data. If you wish to access, correct, update, or delete your personal data, please contact us at <a href="mailto:ilistengpt@gmail.com">ilistengpt@gmail.com</a>. We will make reasonable efforts to fulfill your request, but in some cases, we may need to verify your identity or retain certain data as required by law.</p>
                    <h3>8.Changes to the Privacy Policy</h3>
                    <p>We reserve the right to modify and update this Privacy Policy at any time. We will notify you of any changes by posting an updated version on the website or through other appropriate means. Please review the Privacy Policy periodically for updates</p>
                </div>   
            </section>
            <Footer />
        </div>
      );
}

export default PrivacyPolicy;