import {addItemToPromptTable, retrieveFromPromptTable, deleteFromPromptTable} from '../clients/prompt';
import {addItemToFavoritePromptTable, retrieveFromFavoritePromptTable, deleteFromFavoritePromptTable} from '../clients/favoritePrompt'

/**
 * Singleton class for prompt operation.
 */

class PromptClass {

    static _instance = null;

    constructor() {
        if (PromptClass._instance) {
            throw new Error("Cannot instantiate a Singleton class. Use PromptClass.getInstance()");
        }
        PromptClass._instance = this;
    }

    static getInstance() {
        if (!PromptClass._instance) {
            PromptClass._instance = new PromptClass();
        }
        return PromptClass._instance;
    }

    async initialize() {
        const user = JSON.parse(localStorage.getItem('user'));
        this.userId = user && user.user_id ? user.user_id.S : null;
        this.categoryMap = null;
        this.favorateMap = null;
        if (this.userId) {
            this.categoryMap = this._textToMap(await retrieveFromPromptTable(this.userId));
            this.favorateMap = this._favoriteTextToMap(await retrieveFromFavoritePromptTable(this.userId));
        }
    }

    _textToMap(dataToConvert) {
        const map = new Map();
        if (dataToConvert.Count === 0) return map;
        for (const item of dataToConvert.Items) {
            if (!map.has(item.category.S)) map.set(item.category.S, new Array());
            map.get(item.category.S).push(item);
        }
        return map;
    }

    _favoriteTextToMap(dataToConvert) {
        const map = new Map();
        if (dataToConvert.Count === 0) return map;
        for (const item of dataToConvert.Items) {
            map.set(item.prompt_id.S, item);
        }
        return map;
    }

    async mapRefresh() {
        this.categoryMap = this.userId ? this._textToMap(await retrieveFromPromptTable(this.userId)) : null;
    }

    async favoriteMapRefresh() {
        this.favorateMap = this.userId ? this._favoriteTextToMap(await retrieveFromFavoritePromptTable(this.userId)) : null;
    }

    async addToDB(name, textToBeAdd, promptCategory) {
        const item = {
            userId: this.userId,
            name: name,
            category: promptCategory,
            prompt: textToBeAdd
        }
        await addItemToPromptTable(item);
    }

    async deleteFromDB(primaryKey) {
        await deleteFromPromptTable(primaryKey, this.userId);
    }

    getCatogoryMap() {
        return this.categoryMap;
    }

    async addToMyFavorite(prompt_id) {
        let item = {
            userId: this.userId,
            prompt_id: prompt_id,
        }
        await addItemToFavoritePromptTable(item);
    }

    async deleteFromFavorite(primaryKey) {
        await deleteFromFavoritePromptTable(primaryKey, this.userId);
    }

    getFavorateMap() {
        return this.favorateMap;
    }
}

export default PromptClass;