import React, {useContext, useState, useEffect, useRef} from 'react';
import { Input, ConfigProvider, Form, Radio, message } from 'antd';
import './Chatbar.css';
import { SendOutlined, AudioOutlined, CloseCircleOutlined } from '@ant-design/icons';
import dataContext from '../../dataContext';
import useGeoLocation from "react-ipgeolocation";
import { createCompletion, summarize_long_text } from '../../clients/openai';
import { ToastContainer, toast } from 'react-toastify';
import { addInterviewToDynamoDB } from '../../clients/interviewMapper';
import SpeechClient from '../../clients/speechClient';
import { getCounter } from '../../service/messageCounter';
import { useTranslation, Trans } from 'react-i18next';



const { TextArea } = Input;

/**
 * ------------------------------------------------------
 * |               Input Box and Related                |
 * ------------------------------------------------------
 * @returns 
 */

function ChatComponent(props) {
  const { t, i18n } = useTranslation();

  const { responses, 
          setResponses, 
          selectedSentences, 
          setSelectedSentences, 
          language, gptVersion, 
          userKey, soundDetector, 
          text, 
          setText, 
          setIsStored, 
          isStored, 
          recognizer, 
          setRecognizer,
          isRunning,
          setIsRunning,
          selectBarCollapsed,
          historyBarCollapsed,
          messageRemaining,
          promptRequest,
          setPromptRequest
        } = useContext(dataContext); // This variable saves both sent and received messages in list.
  // const [inputValue, setInputValue] = useState(props.sentences.join('\n'));
  const [loading, setLoading] = useState(false);
  const [summaryLoading, setSummaryLoading] = useState(false);
  const [keywordLoading, setKeywordLoading] = useState(false);
  const [error, setError] = useState(null);
  // below are used for dynamic resizable areas
  const textareaRef = useRef(null);
  const responseContainerRef = useRef(null);
  const [isSupportedLanguage, setIsSupportedLanguage] = useState(false);
  const country = useGeoLocation().country;
  const isAborted=useRef(false);
  const [showCancel, setShowCancel]=useState(false);
  const [seconds, setSeconds] = useState(0);
  const [source, setSource] = useState('video');
  const [isListening, setIsListening] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    const keywordExtractionSupportedLanguage = ["en-US", "es-ES", "pl-PL", "de-DE", "fr-FR",
                                                "it-IT", "nl-NL", "ro-RO", "ru-RU", "pt-BR",
                                                "sv-SE", "ar-EG", "fa-IR", "tr-TR"] //languages supported by keyword extraction.
    setIsSupportedLanguage(keywordExtractionSupportedLanguage.includes(language));
  }, [language]);

  useEffect(() => {
    getCounter().then((count) => messageRemaining.current = count);
  }, []);

  //This useEffect is to send prompt to gpt
  useEffect(() => {
    if(promptRequest) {
      const submit = async () => {
        await handleClick(promptRequest);
      };
      submit();
      setPromptRequest(null);
    }
  }, [promptRequest])

  /**
   * ------------------------------------------------------
   * |              Send and Voice Buttons                |
   * ------------------------------------------------------
   */

  const emptyWarning = () => {
    setLoading(true);
    messageApi.open({
      type: 'warning',
      content: 'Nothing to send...',
    });
    setTimeout(function() {
      setLoading(false);
    }, 2000);
  };

  //store component
  const handleStore = (responseText) => {
    console.log(responseText);
    addInterviewToDynamoDB({
      text: responseText,
      //ILG107 set login state inlocalstorage instead of sessionstorage --Zhan Zhang
      userId: JSON.parse(localStorage.getItem('user')).user_id.S, //get the userId from the session
      timestamp: Date.now().toString(),
      meetingId: localStorage.getItem('meetingID') //the session ensures the meetingId is same in this login period.
    });
    setIsStored(!isStored);
  };

  // send component
  const handleClick = async (messageToSend) => {
    if (messageToSend === '') {
      emptyWarning();
      return;
    }
    setLoading(true);
    setError(null);
    setShowCancel(true);
    
    // if(isAborted.current === true){
    //   toast.warn('Please wait a second before submitting a new question.', {
    //     position: toast.POSITION.TOP_CENTER,
    //     hideProgressBar: true,
    //     autoClose: 1000,
    //   });
    // }else{
           // Show warning if selectedSentences is empty
      // if(selectedSentences.length > 0) {
        var messages = '';
        if (selectBarCollapsed === false) {
          messages = selectedSentences.join(', ');
        } else {
          messages = messageToSend;
        }

        try {
          //pass responses as parameters for supporting context 
          //From Zhan Zhang ILG87
          //TODO: userKey.current
          const responseText = await createCompletion(gptVersion, messages, userKey, responses, messageRemaining);
          if(isAborted.current === true){
            isAborted.current=false;
            setLoading(false);
            setShowCancel(false);
          }else{
            if (responseText) {
              setResponses([...responses, ...responseText]); // The responseText will contain both sent and returned messages.
              handleStore([...responses, ...responseText]); // can not use responses directly, because responses is async and not updated yet.
            } 
          }
        } catch (err) {
          setError(err.message);
        }
      // } else {
      //   toast.warn('No text been selected!', {
      //     position: toast.POSITION.TOP_CENTER,
      //     hideProgressBar: true,
      //     autoClose: 1000,
      //   });
      // }
    // }
    setLoading(false);
    setShowCancel(false);
  };

  const handlecancel = () => {
    isAborted.current=true;
    setLoading(false);
    setShowCancel(false);
  }

  function SendButton() {

      /**
     * ------------------------------------------------------
     * |                       Voice                        |
     * ------------------------------------------------------
     */ 

    const startListening = () => {
      console.log('start listening');
      const speechClient = new SpeechClient("westus", language);
      speechClient.startListening(
        (text) => {
          console.log(`RECOGNIZED: ${text}`);
          setText((prevText) => prevText + text + '\n');
        },
        (error) => {
          console.log(`RECOGNITION ERROR: ${error}`);
        },
        soundDetector
      );
      setRecognizer(speechClient);
    };

    const stopListening = () => {
      if (recognizer) {
        setRecognizer(null);
        recognizer.stopListening(() => {
          recognizer.close();
        }, soundDetector);
      } else if (soundDetector.current === true) {
        soundDetector.current = false;
      }
    };

    const startListeningVideo = () => {
      console.log('start listening');
      const speechClient = new SpeechClient("westus", language);
      speechClient.startListeningVideo(
        (text) => {
          console.log(`RECOGNIZED: ${text}`);
          setText((prevText) => prevText + text + '\n');
        },
        (error) => {
          console.log(`RECOGNITION ERROR: ${error}`);
        },
        soundDetector
      );
      setRecognizer(speechClient);
    };

    const stopListeningVideo = () => {
      if (recognizer) {
        setRecognizer(null);
        recognizer.stopListeningVideo(soundDetector);
      }
    };

    const handleListen = () => {
      if(source === 'speech'){
        if (!recognizer) {
          startTimer();
          startListening();
        } else {
          stopTimer();
          stopListening();
          if (seconds > 0){
            // showMeetingDuration(seconds);
          }
        }
      }else if(source === 'video'){
        if (!recognizer) {
          startTimer();
          startListeningVideo();
        } else {
          stopTimer();
          stopListeningVideo();
          if (seconds > 0){
            // showMeetingDuration(seconds);
          }
        }
      }
    };

    const showMeetingDuration = (seconds) => {
      // if total meeting seconds is less than 1.
      if (seconds < 1){
        const milliseconds = seconds * 1000;
        toast.info(`Meeting duration: ${milliseconds.toFixed(0)} milliseconds`, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000, // Display duration in milliseconds (3 seconds in this case)
      });
      }
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const remainingSeconds = seconds % 60;
    
      const formattedDuration = `${hours}:${minutes}:${remainingSeconds}`;
    
      toast.info(`Meeting duration: ${formattedDuration}`, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000, // Display duration in milliseconds (3 seconds in this case)
      });
    };

    return (
      <ConfigProvider
        theme={{
          token: {
            /* here is your global tokens */
            colorBgContainer: '#2a394b',
            colorText: '#FDF2E9',
            controlHeight: '40px',
            colorBorder: '2a394b',
          },
        }}
      >
        <Radio.Group className='radio'>
          <Radio.Button value="voice" 
                        className= {isRunning ? 'components text_color' : 'components'}
                        onClick={handleListen} >
            <AudioOutlined />
          </Radio.Button>
          {!loading ? 
            <Radio.Button value="send" 
                      className='components' 
                      onClick={() => handleClick(text)} 
                      disabled={loading} >
              <SendOutlined />
            </Radio.Button>
            : 
            <Radio.Button value="cancle" 
                      className='components' 
                      onClick={handlecancel} >
              <CloseCircleOutlined />
            </Radio.Button>}

        </Radio.Group>
      </ConfigProvider>
    );
  }

  /**
   * ------------------------------------------------------
   * |                         Timer                      |
   * ------------------------------------------------------
   */

  useEffect(() => {
    let interval = null;
    if ( isRunning ) {
      interval = setInterval(() => {
        setSeconds(seconds => seconds + 1);
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    }
  }, [isRunning]);

  const startTimer = () => {
    setIsRunning(true);
  };

  // stop the timer
  const stopTimer = () => {
    setIsRunning(false);
    setSeconds(0);
  };

  function Timer() {
    //These two lines of code can make 00 not display 
    {/* hours > 0 && */}
    {/* minutes > 0 */}
  
    // const hours = Math.floor(this.second / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
  
    const timeBlockStyle = {
      backgroundColor: '#2a394b',
      margin: '0 5px',
      display: 'inline-block',
      color: 'white',
    };
  
    return (
      <div className='timer'>
        {/* {<div style={timeBlockStyle}>{hours}</div>}
        {<div>:</div>} */}
        {<div style={timeBlockStyle}>{minutes.toString().padStart(2, '0')}</div>}
        {<div>:</div>}
        <div style={timeBlockStyle}>{remainingSeconds.toString().padStart(2, '0')}</div>
      </div>
    );
  }

  /** 
   * ------------------------------------------------------
   * |                   Main Display                     |
   * ------------------------------------------------------
  */

  return (
    <div className={!selectBarCollapsed && !historyBarCollapsed ? 
         'inputContainer-padding-adjust inputContainer' : 'inputContainer'}>
        <span style={{display: 'flex', flexWrap: 'nowrap'}}>
          <ConfigProvider
            theme={{
              token: {
                /* here is your global tokens */
                colorBgContainer: '#2a394b',
                fontFamily: '-apple-system',
                colorText: '#FDF2E9',
                colorBorder: '#4a6885',
              },
              components: {
                Input: {
                  /* here is your component tokens */
                  activeBorderColor: '#3a506b',
                },
              },
            }}
          >
            <TextArea rows={1}
                      placeholder={t('InterviewPage.sendMessage')} 
                      size='large'
                      autoSize={{
                        minRows: 1,
                        maxRows: 3,
                      }}
                      style={{ maxWidth: '89.0%', resize: 'none' }}
                      onChange={(event) => setText(event.target.value)} 
                      onPressEnter={() => handleClick(text)}
                      value={text} />
          </ConfigProvider>

          <div>
            {contextHolder}
            <SendButton />
            {/* Hide timer if vioce service is not running */}
            {isRunning && <Timer />}
          </div>
        </span>
    </div>
  );
}

export default ChatComponent;