import React, { useState, useCallback, useEffect, useRef } from "react";
import { List, Button, Modal, Input, Typography, message } from "antd";
import { getUserPrompts, updateUserPrompts } from "../../../service/userAction";

import "./UserPrompt.css";
import "../index.css";
import { useTranslation, Trans } from 'react-i18next';

export default function UserPrompt() {
  const [initLoading, setInitLoading] = useState(true);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [promptList, setPromptList] = useState([]);
  const [email, setEmail] = useState("");
  const { t, i18n } = useTranslation();

  let orgPrompt = useRef(null);

  const updateUI = useCallback(() => {
    const user = localStorage.getItem("user");
    if (user) {
      const userObj = JSON.parse(user);
      console.log(userObj);
      setEmail(userObj.email?.S);
      setPromptList(userObj.prompts?.L.map((prompt) => prompt.S) || []);
      getUserPrompts(userObj.user_id?.S).then((prompts) => {
        if (prompts === null) return;
        setPromptList(
          prompts.Items.map((prompt) => {
            return {
              id: prompt.id?.S,
              cat: prompt.category?.S,
              name: prompt.prompt_name?.S,
              text: prompt.prompt_text?.S,
            };
          })
        );
        console.log(prompts);
      });
    }
  }, []);

  useEffect(() => {
    updateUI();
    setInitLoading(false);
  }, [updateUI]);

  const toggleAddModal = useCallback(() => {
    setIsAddModalVisible(!isAddModalVisible);
  }, [isAddModalVisible]);

  const handleAddPrompt = useCallback(() => {
    const name = document.querySelector("#prompt-add-input-name").value;
    const text = document.querySelector("#prompt-add-input-text").value;
    if (!name) {
      message.warning("Your prompt name cannot be empty!");
      return;
    } else if (!text) {
      message.warning("Your prompt text cannot be empty!");
      return;
    } else if (promptList.map((prompt) => prompt.name).includes(name)) {
      message.warning("This prompt already exists!");
      return;
    }

    updateUserPrompts(email, { name, text }, "add")
      .then((user) => {
        if (user !== null) {
          message.success("Prompt added!");
          localStorage.setItem("user", JSON.stringify(user));
          updateUI();
        } else {
          message.error("Failed to add prompt!");
        }
      })
      .finally(() => {
        setIsAddModalVisible(false);
      });
  }, [email, promptList, updateUI]);

  const handleShowPrompt = useCallback((prompt) => {
    Modal.info({
      title: prompt.name,
      content: <span>{prompt.text}</span>,
      onOk() {},
    });
  }, []);

  const toggleEditModal = useCallback(() => {
    setIsEditModalVisible(!isEditModalVisible);
  }, [isEditModalVisible]);

  const handleEditPrompt = useCallback(() => {
    const name = document.querySelector("#prompt-edit-input-name").value;
    const text = document.querySelector("#prompt-edit-input-text").value;
    if (!name) {
      message.warning("Your prompt name cannot be empty!");
      return;
    } else if (!text) {
      message.warning("Your prompt text cannot be empty!");
      return;
    }

    const newPrompt = {
      name,
      text,
      id: orgPrompt.current.id,
    };
    updateUserPrompts(email, newPrompt, "edit")
      .then((user) => {
        if (user !== null) {
          message.success("Prompt edited!");
          localStorage.setItem("user", JSON.stringify(user));
          updateUI();
        } else {
          message.error("Failed to edit prompt!");
        }
      })
      .finally(() => {
        setIsEditModalVisible(false);
        orgPrompt.current = "";
      });
  }, [email, updateUI]);

  const handleDeletePrompt = useCallback(() => {
    Modal.confirm({
      title: t("Warning"),
      content: t("profile.ConfirmDelete"),
      okText: t("profile.Delete"),
      cancelText: t("profile.Cancel"),
      cancelButtonProps: { className: "default-button" },
      onOk() {
        updateUserPrompts(email, orgPrompt.current, "delete")
          .then((user) => {
            if (user !== null) {
              message.success("Prompt deleted!");
              localStorage.setItem("user", JSON.stringify(user));
              updateUI();
            } else {
              message.error("Failed to delete prompt!");
            }
          })
          .finally(() => {
            orgPrompt.current = "";
          });
      },
    });
  }, [email, updateUI]);

  const header = (
    <div className="header-wrapper">
      <p className="pwd-section-title">{t('profile.MyPrompts')}</p>
      <Button type="primary" className="add-btn" onClick={toggleAddModal}>
        {t('profile.Add')}
      </Button>
    </div>
  );

  return (
    <div>
      <List
        header={header}
        loading={initLoading}
        itemLayout="horizontal"
        pagination={{ position: "bottom", align: "center" }}
        dataSource={promptList}
        renderItem={(prompt) => (
          <List.Item
            actions={[
              <Button
                className="default-button"
                onClick={() => {
                  if (prompt.cat !== "personal") return;
                  orgPrompt.current = prompt;
                  toggleEditModal();
                }}
              >
                {t('profile.Edit')}
              </Button>,
              <Button
                type="primary"
                onClick={() => {
                  if (prompt.cat !== "personal") return;
                  orgPrompt.current = prompt;
                  handleDeletePrompt();
                }}
              >
                {t('profile.Delete')}
              </Button>,
            ]}
          >
            <span
              className="prompt-item"
              onClick={() => {
                handleShowPrompt(prompt);
              }}
            >
              {prompt.name}
            </span>
          </List.Item>
        )}
      />
      <Modal
        title={t("profile.Addprompt")}
        open={isAddModalVisible}
        onOk={handleAddPrompt}
        okText={t("profile.Add")}
        cancelText={t("profile.Cancel")}
        onCancel={toggleAddModal}
        cancelButtonProps={{ className: "default-button" }}
        destroyOnClose
        centered
      >
        <Typography.Title level={5}>Name</Typography.Title>
        <Input
          id="prompt-add-input-name"
          placeholder="Input your personal prompt name here..."
        />
        <Typography.Title level={5}>Text</Typography.Title>
        <Input.TextArea
          rows={4}
          id="prompt-add-input-text"
          placeholder="Input your personal prompt text here..."
        />
      </Modal>
      <Modal
        title={t("profile.Edit")}
        open={isEditModalVisible}
        cancelText={t("profile.Cancel")}
        onOk={handleEditPrompt}
        okText={t("profile.Edit")}
        onCancel={() => {
          toggleEditModal();
          orgPrompt.current = null;
        }}
        cancelButtonProps={{ className: "default-button" }}
        destroyOnClose
        centered
      >
        <Typography.Title level={5}>Name</Typography.Title>
        <Input
          id="prompt-edit-input-name"
          defaultValue={orgPrompt.current?.name}
        />
        <Typography.Title level={5}>Text</Typography.Title>
        <Input.TextArea
          rows={4}
          id="prompt-edit-input-text"
          defaultValue={orgPrompt.current?.text}
        />
      </Modal>
    </div>
  );
}
