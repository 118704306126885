import React, { useContext, useEffect, useRef, useState } from 'react';
import { Modal, Button, Segmented, Space, ConfigProvider, Col, DatePicker, Drawer, Form, Input, Row, Select } from 'antd';
import { PlusOutlined, DeleteOutlined, PushpinOutlined, PushpinFilled } from '@ant-design/icons';
import './promptUI.css';
import PromptClass from '../../service/promptHandler';
import dataContext from '../../dataContext';

const { Option } = Select;
/**
 * options, category selection.
 * Add more when needed.
 * @returns 
 */
function PromptModel(props) {
  const { setPromptRequest } = useContext(dataContext);
  const [value, setValue] = useState('All');
  const promptInstance = useRef(PromptClass.getInstance());
  const [categoryMap, setCategoryMap] = useState(null);
  const [favorateMap, setFavorateMap] = useState(null);
  const category = ['All', 'Text', 'Personal', 'My Favorite'];

  //Add prompt button
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const handleSubmit = async () => {
    const inputs = form.getFieldsValue();
    await promptInstance.current.addToDB(inputs.Name, inputs.Text, 'personal');
    updateCategoryMap();
  };

  //Initialize variable inside promptClass.
  useEffect(() => {
    async function Initialize() {
      await promptInstance.current.initialize();
      setCategoryMap(promptInstance.current.getCatogoryMap());
      setFavorateMap(promptInstance.current.getFavorateMap());
    } 
    Initialize();
  }, []);

  async function updateCategoryMap() {
    await promptInstance.current.mapRefresh();
    setCategoryMap(promptInstance.current.getCatogoryMap());
  }

  async function updateFavoriteMap() {
    await promptInstance.current.favoriteMapRefresh();
    setFavorateMap(promptInstance.current.getFavorateMap());
  }

  const handleDelete = async (primaryKey) => {
    await promptInstance.current.deleteFromDB(primaryKey);
    updateCategoryMap();
    if (favorateMap.has(primaryKey)) {
      await promptInstance.current.deleteFromFavorite(favorateMap.get(primaryKey).id.S);
      await updateFavoriteMap();
    }
  }

  const handleFavoriteClick = async (prompt_id) => {
    if (favorateMap.has(prompt_id)) {
      await promptInstance.current.deleteFromFavorite(favorateMap.get(prompt_id).id.S);
    } else {
      await promptInstance.current.addToMyFavorite(prompt_id);
    }
    await updateFavoriteMap();
  }

  function handleChat(promptMessage) {
    setPromptRequest(promptMessage);
    props.setIsPromptModalOpen(false);
  }

  //Display prompt buttons
  function DisplayByCategory() {
    if (categoryMap === null) return (<></>);
    if (value === 'All') {
      return (
        <div>
          <Space wrap size={'large'}>
            {[...categoryMap].map(([key, value]) => (
                value.map(item =>
                  <div className='button-container'>
                    <Button key={item.prompt_name.S} className='button-layout' onClick={() => handleChat(item.prompt_text.S)}>{item.prompt_name.S}</Button>
                    {item.category.S === "personal" &&
                      <Button 
                        key={item.prompt_name.S + "_del"} 
                        className='delete-button'
                        onClick={() => handleDelete(item.id.S)}>
                        <DeleteOutlined />
                      </Button>}
                    <Button 
                      key={item.prompt_name.S + "_fav"} 
                      className={favorateMap.has(item.id.S) == false ? 'pin-button' : 'selected-pin-button'}
                      onClick={() => handleFavoriteClick(item.id.S)}>
                      {favorateMap.has(item.id.S) == false ? <PushpinOutlined /> : <PushpinFilled />}
                    </Button>
                  </div>
                )
            ))}
          </Space>
        </div>
      )
    } else if (value === 'My Favorite') {
      return (
        <div>
          <Space wrap size={'large'}>
            {[...categoryMap].map(([key, value]) => (
                value.map(item =>
                  favorateMap.has(item.id.S) ? 
                    <div className='button-container'>
                      <Button key={item.prompt_name.S} className='button-layout' onClick={() => handleChat(item.prompt_text.S)}>{item.prompt_name.S}</Button>
                      <Button 
                        key={item.prompt_name.S + "_fav"} 
                        className={favorateMap.has(item.id.S) == false ? 'pin-button' : 'selected-pin-button'}
                        onClick={() => handleFavoriteClick(item.id.S)}>
                        {favorateMap.has(item.id.S) == false ? <PushpinOutlined /> : <PushpinFilled />}
                      </Button>
                    </div>
                  : <></>
                )
            ))}
          </Space>
        </div>
      )
    } else {
      let itemList = categoryMap.get(value.toLowerCase());
      if (itemList === undefined) return (<></>);
      return (
        <div>
          <Space wrap size={'large'}>
            {itemList.map(item =>
                <div className='button-container'>
                  <Button key={item.prompt_name.S} className='button-layout' onClick={() => handleChat(item.prompt_text.S)}>{item.prompt_name.S}</Button>
                  {item.category.S === "personal" &&
                  <Button 
                    key={item.prompt_name.S + "_del"} 
                    className='delete-button'
                    onClick={() => handleDelete(item.id.S)}>
                    <DeleteOutlined />
                  </Button>}
                  <Button 
                    key={item.prompt_name.S + "_fav"} 
                    className={favorateMap.has(item.id.S) == false ? 'pin-button' : 'selected-pin-button'}
                    onClick={() => handleFavoriteClick(item.id.S)}>
                    {favorateMap.has(item.id.S) == false ? <PushpinOutlined /> : <PushpinFilled />}
                  </Button>
                </div>
              )
            }
          </Space>
        </div>
      )
    }
  }

  //Drawer for Add Prompt Button.
  function AddPromptDrawer() {
    return (
      <Drawer
        title="Add a New Prompt"
        width={480}
        onClose={onClose}
        open={open}
        styles={{
          body: {
            paddingBottom: 80,
          },
        }}
        extra={
          <Space>
            <Button onClick={onClose}>Cancel</Button>
            <Button onClick={() => {
                onClose();
                handleSubmit();
              }} type="primary">
              Submit
            </Button>
          </Space>
        }
      >
        <Form layout="vertical" form={form} hideRequiredMark>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="Name"
                label="Prompt Name"
                rules={[
                  {
                    required: true,
                    message: 'Please enter prompt name',
                  },
                ]}
              >
                <Input placeholder="Please enter prompt name" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="Text"
                label="Prompt Text"
                rules={[
                  {
                    required: true,
                    message: 'please enter prompt here.',
                  },
                ]}
              >
                <Input.TextArea rows={4} placeholder="please enter prompt here." />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    );
  }

  return (
      <div>
        <Segmented options={category} value={value} onChange={setValue} />
        <Button type="primary" onClick={showDrawer} icon={<PlusOutlined />} className='add-button'>
          Add Prompt
        </Button>
        <AddPromptDrawer />
        <div className='content-layout'>
          <DisplayByCategory />
        </div>
      </div>
  );
}

function PromptDisplay(props) {
    return (
      <Modal
        open={props.isPromptModalOpen} 
        onCancel={() => props.setIsPromptModalOpen(false)}
        footer={<></>} 
        width={1000}
      >
        <PromptModel setIsPromptModalOpen={props.setIsPromptModalOpen}/>
      </Modal>
    )
}

export default PromptDisplay;