import { dynamodb } from "./dynamoClient.js";

export function addItemToFavoritePromptTable(item) {
  const params = {
    TableName: "user_favorite_table",
    Item: {
      id: { S: Math.random().toString(36).substring(7) },
      user_id: { S: item.userId || "" },
      prompt_id: { S: item.prompt_id },
    },
  };

  return new Promise((resolve, reject) => {
    dynamodb.putItem(params, function (err, data) {
      if (err) {
        console.error(err);
        reject(err);
      } else {
        console.log(data);
        resolve(data);
      }
    });
  });
}


export function retrieveFromFavoritePromptTable(userID) {
  const params = {
    TableName: "user_favorite_table",
    ExpressionAttributeValues: {
      ":user_id": { S: userID },
    },
    FilterExpression: "user_id = :user_id",
  };

  return new Promise((resolve, reject) => {
    dynamodb.scan(params, function (err, data) {
      if (err) {
        reject(err);
      } else {
        resolve(data);
      }
    });
  });
}

export function deleteFromFavoritePromptTable(primaryKey, user_id) {
  const param = {
    TableName: 'user_favorite_table',
    Key: {
      'id': { S: primaryKey }, // The update operation only supports the primary key.
      'user_id': { S: user_id }
    },          
  };

  return new Promise((resolve, reject) => {
    dynamodb.deleteItem(param, function(err, data) {
      if (err) {
        console.error('Error: ' + err);
        reject(err);
      } else {
        resolve(data);
      }
    });
  });
}
