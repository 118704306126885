import { dynamodb } from './dynamoClient.js';

/**
 * Used to retrieve the user message limit from the database, the userId is primary key.
 * @param {*} userId 
 * @returns 
 */

export function retrieveMessageCounter(userId) {
    const params = {
      TableName: 'message_limit',
      KeyConditionExpression: 'userId = :userId',
      ExpressionAttributeValues: {
        ':userId': { 'S': userId }
      }
    };
  
    return new Promise((resolve, reject) => {
      dynamodb.query(params, function(err, data) {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    });
  }

/**
 * Used to create the user message limit from the database, the userId is primary key.
 * @param {*} userId 
 * @returns 
 */

  export function createMessageCounter(userId) {
    const lastMessageDate = new Date().toISOString().slice(0, 10);
    const params = {
      TableName: 'message_limit',
      Item: {
        'userId': { 'S': userId },
        'lastMessageDate': { 'S': lastMessageDate },
        'messageRemained': { 'N': '20' },
      }
    };
  
    dynamodb.putItem(params, function(err, data) {
      if (err) console.log(err);
    });
  }

/**
 * Used to update the user message limit from the database, the userId is primary key.
 * @param {*} userId 
 * @returns 
 */

export async function updateMessageCounter(userId, count, date) {
  const param = {
    TableName: 'message_limit',
    Key: {
      'userId': { 'S': userId } // The update operation only supports the primary key.
    },          
    UpdateExpression: 'SET #messageRemained = :count, lastMessageDate = :date',
    ExpressionAttributeNames: {
      '#messageRemained': 'messageRemained' // COLUMN NAME       
    },
    ExpressionAttributeValues: {
      ':count': { 'N': count.toString() },
      ':date': { 'S': date }
    }
  };

  dynamodb.updateItem(param, function(err, data) {
    if (err) { console.log('Error: ' + err); }
  });
};




